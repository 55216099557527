import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ['tooltip'];

  displayTooltip() {
    this.tooltipTarget.classList.remove('hidden');
  }

  hideTooltip() {
    this.tooltipTarget.classList.add('hidden');
  }

}
