import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ["menu", "toggleButton"];

  static values = {
    ignorePageScroll: Boolean,
    useProgramBanner: Boolean
  }

  toggle() {
    if (this.useProgramBannerValue) {
      this._setMenuPosition();
    }

    this.menuTarget.classList.toggle("hidden");

    if (!this.menuTarget.classList.contains('hidden')) {
      document.addEventListener("click", this._handleClick.bind(this));
    }
  }

  _handleClick(event) {
    if (this.menuTarget != event.target &&
      this.toggleButtonTarget != event.target &&
      this.toggleButtonTarget != event.target.parentNode) {
      this._hideMenu();
    }
  }

  _hideMenu() {
    this.menuTarget.classList.add("hidden");
    document.removeEventListener("click", this._handleClick.bind(this));
  }

  _setMenuPosition() {
    const buttonY = this.menuTarget.previousElementSibling.getBoundingClientRect().y;
    const programBannerOffset = document.getElementById("js-program-banners")?.offsetHeight || 0;

    if (this.ignorePageScrollValue) {
      this.menuTarget.style.top = `${buttonY - 30 - programBannerOffset}px`;
    } else {
      this.menuTarget.style.top = `${window.scrollY + buttonY - 30 - programBannerOffset}px`;
    }
  }
}
