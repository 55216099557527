// IE <= 10 => MSIE. IE 11 => Trident
if (navigator.userAgent.indexOf("MSIE") === -1 && navigator.userAgent.indexOf("Trident") === -1) {
  projectId = document.querySelector('meta[name="projectId"]')?.content
  projectKey = document.querySelector('meta[name="projectKey"]')?.content
  host = document.querySelector('meta[name="host"]')?.content
  environment = document.querySelector('meta[name="environment"]')?.content
  version = document.querySelector('meta[name="version"]')?.content

  try {
    var airbrake = new Airbrake.Notifier({
      projectId: projectId,
      projectKey: projectKey,
      host: host
    });

    airbrake.addFilter(function (notice) {
      notice.context.environment = environment;
      notice.context.version = version;
      return notice;
    });
  } catch (_) {
    // #
  }
}
