export class TrixShareEmbed {
  constructor(event, trixAttachment) {
    this.trixAttachment = trixAttachment;
    this.editor = event.target.editor;
    this.trixElement = event.target;
    this.toolbar = this.trixElement.toolbarElement;
  }

  init() {
    this.displayEmbedToolbar();
  }

  displayEmbedToolbar() {
    const attachmentType = this.trixAttachment.dataset.trixContentType;
    const attachmentToolbar = this.trixAttachment.querySelector('.attachment__toolbar');
    const attachmentActions = attachmentToolbar.querySelector('.trix-button-group--actions');

    const isEmbedAttachment = !attachmentType.startsWith('image') ||
      attachmentType == "application/octet-stream" ||
      attachmentType == "undefined";

    attachmentActions.classList.add('with-embeds');

    Array.from(this.trixAttachment.children)
      .filter(childEl => childEl.classList.contains('embed-container'))
      .forEach(embedEl => {
        const copyIcon = embedEl.querySelector('.trix-button--icon-copy-url');
        let copyLink;

        if (copyIcon) {
          copyIcon.parentElement.remove();
          copyLink = copyIcon.dataset.clipboardUrl;
        } else {
          const iframeEl = embedEl.querySelector('iframe');
          if (iframeEl) copyLink = iframeEl.src;
        }

        if (copyLink) {
          attachmentActions.insertAdjacentHTML(
            "afterbegin", this.copyURLButton(copyLink)
          );
        }

        const toolbarCopyIcon = attachmentActions
          .querySelector('.trix-button--icon-copy-url');

        if (toolbarCopyIcon) {
          toolbarCopyIcon.addEventListener('click', this.copyUrl.bind(this));
          toolbarCopyIcon.classList.add('toolbar__icon');
        }
      });
  }

  copyUrl(e) {
    e.preventDefault();
    const copyButton = e.currentTarget;

    if (copyButton.dataset.clipboardAction === "copy") {
      navigator.clipboard.writeText(copyButton.dataset.clipboardUrl.trim());

      const copyContainer = copyButton.parentElement,
            copyMessage = copyContainer.querySelector('.copy-embed-message');
      copyButton.setAttribute('data-clipboard-action', 'copied');

      if (copyMessage) {
        copyMessage.style.display = "inline-block";
        copyMessage.classList.remove('hidden');
        copyContainer.style.marginLeft = "26px";

        setTimeout(() => {
          copyMessage.style.display = "none";
          copyContainer.style.marginLeft = "0px";
          copyButton.setAttribute('data-clipboard-action', 'copy');
        }, 2000);
      }
    } else if (copyButton.dataset.clipboardAction === "copied") {
      copyButton.title = copyButton.dataset.clipboardTitle;
      copyButton.setAttribute('data-clipboard-action', 'copy');
    }
  }

  copyURLButton(url) {
    const lang = PromoteRichTextEditor.translations;

    return `
      <div class="embed-link-copy">
        <button class="trix-button--icon-copy-url"
                title="${lang.copyEmbedTitle}"
                data-clipboard-action="copy"
                data-clipboard-url="${url}">
        </button>
        <span class="copy-embed-message hidden">
          ${lang.copyEmbedNotice}
        </span>
      </div>`;
  }
}
