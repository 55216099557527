import { Controller } from "stimulus";
export default class extends Controller {
  static targets = ['virtualForm', 'locationForm'];

  displayVirtualForm() {
    if (this.virtualFormTarget.classList.contains('hidden')) {
      this.locationFormTarget.classList.add('hidden');
      this.virtualFormTarget.classList.remove('hidden');
    }
  }

  displayLocationForm() {
    if (this.locationFormTarget.classList.contains('hidden')) {
      this.locationFormTarget.classList.remove('hidden');
      this.virtualFormTarget.classList.add('hidden');
    }
  }
}
