import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ['collapsible', 'icon']

  toggle() {
    this.collapsibleTarget.classList.toggle('hidden');

    if (this.hasIconTarget) {
      this.iconTarget.classList.toggle('rotate-180');
    }
  }
}
