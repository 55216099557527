import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [ 'radioButtons', 'startDate', 'endDate', 'offset' ]

  connect() {
    this.currentStartDate = this._getStartDate()
  }

  onDateChange() {
    this._syncRadioButtonsState();
    this._syncOffset();
    this._updateDateLimits();
  }

  _syncOffset() {
    let newOffset = this._calculateOffset();
    this._updateOffset(newOffset);
  }

  _calculateOffset() {
    const startDate = this._getStartDate();
    const endDate = this._getEndDate();
    const oneDay = 1000 * 3600 * 24;
    let diffDays = 0;

    if (startDate && endDate) {
      if (startDate.getTime() === endDate.getTime()) {
        diffDays = 0;
      } else {
        let diffTime = endDate.getTime() - startDate.getTime();
        diffDays = diffTime / oneDay;
      }
    }

    return diffDays;
  }

  _syncRadioButtonsState() {
    const newDate = this._getStartDate();

    if (!newDate) {
      return;
    }

    if (this.currentStartDate && this.currentStartDate.valueOf() === newDate.valueOf()) {
      this.radioButtonsTarget.setAttribute('disabled', 'true');
      this.radioButtonsTarget.classList.add('opacity-60');
    } else {
      this.radioButtonsTarget.removeAttribute('disabled');
      this.radioButtonsTarget.classList.remove('opacity-60');
    }
  }

  _updateOffset(newOffset) {
    let offsetTextDiv = this.offsetTarget.querySelector('.badge');

    if (newOffset === 0) {
      offsetTextDiv.innerText = '';
    } else {
      offsetTextDiv.innerText = newOffset;
    }
  }

  _updateDateLimits() {
    const startDate = this._getStartDate();
    const endDate = this._getEndDate();

    if (startDate) {
      const input = 'input[data-components--admin-inputs-date-picker-target="input"]'
      const endDateInput = this.endDateTarget.querySelector(input);
      endDateInput.min = startDate.toISOString().split('T')[0];
    } else {
      const input = 'input[data-components--admin-inputs-date-picker-target="input"]'
      const endDateInput = this.endDateTarget.querySelector(input);
      endDateInput.min = '';
    }

    if (endDate) {
      const input = 'input[data-components--admin-inputs-date-picker-target="input"]'
      const startDateInput = this.startDateTarget.querySelector(input);
      startDateInput.max = endDate.toISOString().split('T')[0];
    } else {
      const input = 'input[data-components--admin-inputs-date-picker-target="input"]'
      const startDateInput = this.startDateTarget.querySelector(input);
      startDateInput.max = '';
    }
  }

  _getStartDate() {
    const input = 'input[data-components--admin-inputs-date-picker-target="input"]'
    const dateInput = this.startDateTarget.querySelector(input);

    if (dateInput.value !== '') {
      return new Date(dateInput.value);
    }

    return null;
  }

  _getEndDate() {
    const input = 'input[data-components--admin-inputs-date-picker-target="input"]'
    const dateInput = this.endDateTarget.querySelector(input);

    if (dateInput.value !== '') {
      return new Date(dateInput.value);
    }

    return null;
  }
}
