import { ajax } from '@rails/ujs';
import { TrixShareEmbed } from "./trix_share_embed";

export class TrixShareEmbedToolbar {
  constructor(event) {
    this.trixElement = event.target;
    this.toolbar = this.trixElement.toolbarElement;
  }

  init() {
    this.trixElement.addEventListener(
      'trix-selection-change',
      this.toolbarEventListener.bind(this)
    );

    this.initializeCopyButtons();
  }

  initializeCopyButtons() {
    Array
      .from(this.trixElement.querySelectorAll(".embed-container"))
      .forEach(embedElement => {
        const copyIcon = embedElement.querySelector(".trix-button--icon-copy-url");

        if (copyIcon) {
          copyIcon.classList.add('toolbar__icon');
        }
      });
  }

  toolbarEventListener(event) {
    const trixAttachment = this.trixElement
      .querySelector('figure[data-trix-mutable="true"]');

    if (trixAttachment) {
      const trixShareEmbed = new TrixShareEmbed(event, trixAttachment);

      trixShareEmbed.init();
    }
  }
}
