export class TrixHeading {
  constructor(event) {
    this.editor = event.target.editor;
    this.trixElement = this.editor.element;
    this.toolbar = this.trixElement.toolbarElement;
    this.headerContainer = this.toolbar.querySelector("[data-behavior='dropdown_headers']");
    this.headerElement = this.toolbar.querySelector("[data-behavior='header_selection']");
    this.headerItems = this.headerContainer.querySelectorAll('.trix-picker-item');
    this.activeHeader = this.toolbar.querySelector('.trix-picker-label .trix-active');
    this.defaultHeader = this.headerContainer.querySelector(".trix-picker-item.trix-default");
  }

  init() {
    this.initEventListeners();
  }

  initEventListeners() {
    this.defaultHeader.addEventListener("click", this.onReset.bind(this));
    this.trixElement.addEventListener('trix-selection-change', this.onSelect.bind(this));
    Array.from(this.headerItems).forEach(item => item.addEventListener("click", this.onChange.bind(this)));
  }

  onReset() {
    let range = this.editor.getSelectedRange();
    this.editor.setSelectedRange(range);

    Array.from(['heading1', 'heading2', 'heading3']).forEach((attrTag) => {
      const headerElements = this.toolbar.querySelectorAll(`[data-trix-attribute="${attrTag}"]`);
      this.editor.deactivateAttribute(attrTag);

      headerElements.forEach((headerItem) => {
        headerItem.classList.remove("trix-active", "trix-selected");
        Array.from([
          "selected", "data-trix-active", "data-trix-selected"
        ]).forEach(headerAttr => headerItem.removeAttribute(`${headerAttr}`));
      });
    });

    if (!this.defaultHeader.hasAttribute('data-trix-attribute')) {
      this.defaultHeader.setAttribute('selected', 'selected')
    }
    this.defaultHeader.classList.add('trix-selected');
    this.headerElement.style.color = "#444";
    this.trixElement.focus();
  }

  onSelect() {
    this.resetHeaderSelection();
  }

  onChange(event) {
    let input = event.target;
    this.resetHeaderSelection();

    if (input.hasAttribute('data-trix-attribute')) {
      let headerAttr = input.dataset.trixAttribute;
      let headerItem = this.headerElement.querySelector(`[data-trix-attribute="${headerAttr}"]`);
      let isActive = headerItem.classList.contains('trix-active');
      headerItem.classList.toggle('trix-selected', isActive);
      input.classList.toggle('trix-selected', isActive);

      if (isActive) {
        this.headerElement.style.color = "#06c";
        headerItem.setAttribute('selected', '');
      } else {
        this.headerElement.style.color = "#444";
        headerItem.removeAttribute('selected');
      }
    }
    else {
      this.defaultHeader.classList.add('trix-selected');
      this.headerElement.style.color = "#444";
      input.classList.remove('trix-selected');
    }

    this.trixElement.focus();
  }

  resetHeaderSelection() {
    Array.from(this.headerItems).forEach(option => {
      option.classList.remove('trix-selected')
    });

    Array.from(this.headerElement.options).forEach(dropdown => {
      this.selectHeaderLevel(dropdown)
    });
  }

  selectHeaderLevel(dropdown) {
    if (this.editor.attributeIsActive(dropdown.dataset.trixAttribute)) {
      dropdown.setAttribute('selected', '')
    } else {
      dropdown.removeAttribute('selected')
    }

    if (dropdown.hasAttribute('data-trix-active') ||
      dropdown.classList.contains('trix-active')) {
      dropdown.classList.add('trix-selected')
    } else {
      dropdown.classList.remove('trix-selected')
    }

    if (dropdown.classList.contains('trix-selected')) {
      this.headerElement.text = dropdown.text;
    } else {
      this.headerElement.text = 'Normal';
    }

    if (this.headerElement.value === "Normal") {
      this.headerElement.style.color = "#444";
    } else {
      this.headerElement.style.color = "#06c";
    }

    if (this.activeHeader) this.activeHeader.classList.add('trix-selected')
  }
}
