import { Controller } from "stimulus";
export default class extends Controller {
  static targets = ["template"];

  connect() {
    document.addEventListener('promote:editor-dragend', this._onDragEnd.bind(this));
  }

  disconnect() {
    document.removeEventListener('promote:editor-dragend', this._onDragEnd.bind(this));
  }

  _onDragEnd(event) {
    setTimeout(() => {
      this._updateItemCounters();
    }, 100);
  }

  addScheduleItem(event) {
    event.preventDefault();

    let newForm = this.templateTarget.cloneNode(true);
    newForm.dataset.programBuilderScheduleTarget = '';

    const scheduleSection = event.target.closest('.js-editor-schedule-section');
    const items = scheduleSection.querySelectorAll('.js-editor-schedule-item');

    if (items.length) {
      const lastItem = items[items.length - 1];

      lastItem.after(newForm);
    } else {
      scheduleSection.append(newForm);
    }

    newForm.dataset['editor-ScheduleTarget'] = '';
    newForm.querySelector('.js-schedule-item-content').classList.remove('collapsed');
    newForm.classList.remove("hidden");
    newForm.scrollIntoView({ behavior: "smooth", block: 'nearest', inline: "center"});

    this._updateItemCounters();
  }

  removeScheduleItem(event) {
    event.preventDefault();

    let scheduleItem = event.target.closest(".js-editor-schedule-item");

    scheduleItem.remove();

    this._updateItemCounters();
  }

  _updateItemCounters() {
    const visibleItems = document.querySelectorAll('.js-editor-schedule-item:not(.hidden)');

    visibleItems.forEach((item, index) => {
      this._updateFormNames(item, index + 1);
    });
  }

  _updateFormNames(element, counterStrings) {
    var fields = element.querySelectorAll('[name]');

    fields.forEach(field => {
      if (field.name.includes('_index_')) {
        field.name = field.name.replace('_index_', counterStrings);
        field.id = field.id.replace('_index_', counterStrings);
      } else {
        field.name = field.name.replace(/\d+/, counterStrings);
        field.id = field.id.replace(/\d+/, counterStrings);
      }
    });
  }
}
