import { ajax } from '@rails/ujs';
import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ['lockByDateForm', 'form', 'datePickerInput']

  displayForm() {
    this.lockByDateFormTarget.classList.remove('hidden')
    this._requireDatePicker(true);
  }

  hideForm() {
    this.lockByDateFormTarget.classList.add('hidden')
    this._requireDatePicker(false);
  }

  _requireDatePicker(value) {
    if (this.hasDatePickerInputTarget) {
      this.datePickerInputTarget.required = value;
    }
  }

  preventToggle() {
    event.preventDefault();
  }

  submitForm(event) {
    event.preventDefault();

    const form = this.formTarget;
    const formData = new FormData(form);

    const action = form.action;
    const method = form.method;

    ajax({
      url: action,
      type: method,
      data: formData,
      beforeSend: (xhr) => {
        this.ajaxRequest = xhr;
        return true;
      }
    });
  }
}


