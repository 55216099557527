import Rails, { enableElement } from '@rails/ujs';
import { Controller } from 'stimulus';

import PromoteHelpers from '../ng_admin/deprecated_promote_helpers.js';

var _ = require("lodash");
const KEYCODE_TAB = 9;
var debounce_ms = 200;

export default class extends Controller {
  static targets = ["errorMessage", "inlineMessage", "hint"];

  static values = {
    unloadWarningEnabled: Boolean
  }

  connect() {
    this.inputsChanged = new Set();
    this.errorClass = "error";
    this.baseErrorClass = "base-error";
    this.fieldContainerSelector = ".input-form";
    this.errorContainerSelector = ".form-error";
    this.replyContainerSelector = ".reply-field";
    this.feedContainerSelector = ".feed-field";
    this.assistantContainerSelector = ".assistant-field";
    this.trixEditor = 'trix-editor';

    this.form = this.element.closest("form");
    this.form.addEventListener("blur", this.onBlur, true);

    this.keyUpHandler = this._staticDebouncer(this.onKeyup, debounce_ms);
    this.changeEventHandler = this._staticDebouncer(this.onChangeEvent, debounce_ms);
    this.clickHandler = this._staticDebouncer(this.onClick, debounce_ms);

    this.form.addEventListener("keyup", this.keyUpHandler);
    this.form.addEventListener("click", this.clickHandler);
    this.form.addEventListener("change", this.changeEventHandler);
    this.form.addEventListener("trix-attachment-add", this.onFileAdd);
    this.form.addEventListener("trix-file-accept", this.onFileAccepted);
    this.form.addEventListener("submit", this.onSubmit);
    this.form.addEventListener("ajax:beforeSend", this.onSubmit);

    this.doNotUseBrowserDefaultHTML5Validation();


    if (this.unloadWarningEnabledValue) {
      document.addEventListener("promote:cancel-changes", this.onCancelChanges);
      document.addEventListener("turbolinks:before-visit", this.onBeforeUnloadTurbo);
    }
  }

  disconnect() {
    this.inputsChanged.clear();

    this.form.removeEventListener("blur", this.onBlur);
    this.form.removeEventListener("keyup", this.keyUpHandler);
    this.form.removeEventListener("change", this.changeEventHandler);
    this.form.removeEventListener("click", this.clickHandler);
    this.form.removeEventListener("trix-attachment-add", this.onFileAdd);
    this.form.removeEventListener("trix-file-accept", this.onFileAccepted);
    this.form.removeEventListener("submit", this.onSubmit);
    this.form.removeEventListener("ajax:beforeSend", this.onSubmit);

    if (this.unloadWarningEnabledValue) {
      document.removeEventListener("promote:cancel-changes", this.onCancelChanges);
      document.removeEventListener("turbolinks:before-visit", this.onBeforeUnloadTurbo);
    }
  }

  anyPendingForms() {
    return this.unloadWarningEnabledValue && this.inputsChanged.size > 0
  }

  onChange(id) {
    if ( id )
    {
      this.inputsChanged.add(id);
      PromoteHelpers.dispatchEvent({
        target: document,
        type: 'promote:input-changed',
        detail: {
          'id': id
        }
      });
    }
  }

  onCancelChanges = (event=null) => {
    this.inputsChanged.delete(event?.detail?.id);
  }

  onBeforeUnloadTurbo = (event) => {
    if ( this.inputsChanged.size > 0 )
    {
      if ( Rails.confirm(null, event.target.activeElement, this.element.querySelector(this.trixEditor)?.dataset ) )
      {
        this.inputsChanged.clear();

        // the originalEvent should exist for events dispatched by the VAT component
        if ( event.detail !== undefined )
        {
          PromoteHelpers.dispatchEvent({
            target: document,
            type: 'promote:confirm-box',
            detail: {
              originalEvent: event.detail?.originalEvent,
            }
          });
        }
      }
      else
      {
        event.preventDefault();
      }
    }
  }

  onBlur = (event) => {
    this.hideBaseError();
    this.validateField(event.target, true);
    if (event.target.nextElementSibling) {
      this.validateField(event.target.nextElementSibling, true);
    }
  };

  onKeyup = (event) => {
    let target = event.target;
    let isValid;

    // Ignore tab keyup event
    if (event.keyCode === KEYCODE_TAB){
      return false;
    }

    if (target.nodeName === 'SPAN') {
      target = target.nextElementSibling;
    }

    if (target.id.match(/trix_input/g) || target.id.match(/trix_textarea/g)) {
      isValid = target.value !== "";
    } else {
      if (target.tagName === "FORM" || target.tagName === "INPUT") {
        isValid = target.checkValidity();
      } else {
        isValid = target.closest("form").checkValidity();
      }
    }

    if (isValid) {
      this.onChange(event.target.id);
      this.hideError(event.target);
    } else if (event.target.getAttribute("placeholder") !== "" && this.unloadWarningEnabledValue) {
        this._dispatchCancelEvent(event.target.id);
      }
  }

  onClick = (event) => {
    let target = event.target;
    let isValid;

    if (target.type === 'radio') {
      isValid = target.closest("form").checkValidity();
    } else {
      return;
    }

    if (isValid) {
      this.onChange(event.target.id);
      this.hideError(event.target);
    } else if (event.target.getAttribute("placeholder") !== "" && this.unloadWarningEnabledValue) {
      this._dispatchCancelEvent(event.target.id);
    }
  }

  onChangeEvent = (event) => {
    let target = event.target;

    if (target.nodeName !== 'INPUT') { return; }

    let isValid = target.closest("form").checkValidity();

    if (isValid) {
      this.onChange(target.id);
      this.hideError(target);
    } else if (event.target.getAttribute("placeholder") !== "" && this.unloadWarningEnabledValue) {
      this._dispatchCancelEvent(event.target.id);
    }
  }

  onFileAdd = (event) => {
    this.onChange(event.target.id);
    this.hideError(event.target);
  }

  onFileAccepted = (event) => {
    let maxFileAttachmentSize = this.form.querySelector(this.trixEditor)
      .dataset['maxFileAttachmentSize'];

    if (event.file.size > maxFileAttachmentSize) {
      this.showMaxSizeAttachmentError(event);
    }
  }

  anyAttachmentPending() {
    var elms = Array.from(this.element.querySelectorAll(this.trixEditor));
    var attachs = elms.flatMap(trix => trix.editor.getDocument().getAttachments())

    return attachs.some(attachment => attachment.isPending());
  }

  onSubmit = (event) => {
    this.hideBaseError();

    if (!this.validateForm()) {
      event.preventDefault();
      this.enableUnobtrusiveJavaScriptElements();
    } else if ( this.anyAttachmentPending() ) {
      this.showPendingAttachmentError(event);
    } else {
      if (this.unloadWarningEnabledValue) {
        this._dispatchCancelEvent(event.target.querySelector(this.trixEditor)?.id)
      }

      this.inputsChanged.clear();

      this._resetReplyField(event);
      this._resetFeedField(event);
      this._resetAssistantField(event);
    }
  };

  validateForm() {
    let isValid = true;

    this.formFields.forEach((field) => {
      if (this.shouldValidateField(field) && !this.validateField(field)) {
        isValid = false;
      }
    });

    return isValid;
  }

  validateField(field, blurAction = false) {
    if (!this.shouldValidateField(field, blurAction)) {
      return true;
    }

    let isValid = field.checkValidity();

    if (field.id.match(/trix_input/g)){
      let fieldId = field.id.match(/^trix_input_[0-9]*/)[0];

      isValid = (document.getElementById(fieldId)?.dataset?.allowEmpty === 'true')
        || !(/^\s*$/.test(field.value.replace(/(<([^>]+)>)|&nbsp;/ig, "")));
    }

    if (!isValid) {
      this.refreshErrorForInvalidField(field, isValid);
    }

    return isValid;
  }

  shouldValidateField(field, blurAction) {
    const fieldEnabled = !field.disabled;
    const allowedType = !["file", "reset", "submit", "button", "span"].includes(
      field.type
    );
    const respondsToCheckValidity = typeof field.checkValidity == "function";
    const notContentEditable = !field.isContentEditable;

    const shouldValidate =
      fieldEnabled &&
      allowedType &&
      respondsToCheckValidity &&
      notContentEditable;

    if (
      blurAction &&
      (field.tagName === "TEXTAREA" || field.tagName === "INPUT")
    ) {
      return shouldValidate && field.value.length > 0;
    } else {
      return shouldValidate;
    }
  }

  refreshErrorForInvalidField(field, isValid) {
    this.hideError(field);

    if (!isValid) {
      this.showError(field);
    }
  }

  hideError(field) {
    const fieldContainer = field.closest(this.fieldContainerSelector);
    if (!fieldContainer) {
      return;
    }

    fieldContainer.classList.remove(this.errorClass);

    if (this.hasInlineMessageTarget) {
      this.inlineMessageTargets.forEach(inlineMsg => {
        var msgFieldContainer = inlineMsg.closest(this.fieldContainerSelector);

        if (!msgFieldContainer || !msgFieldContainer.classList.contains('error')) {
          inlineMsg.classList.remove('invisible');
        }
      }, this);
    }

    if (this.hasHintTarget) {
      this.hintTargets.forEach(hint => {
        var msgFieldContainer = hint.closest(this.fieldContainerSelector);

        if (!msgFieldContainer || !msgFieldContainer.classList.contains('error')) {
          hint.classList.remove('invisible');
        }
      }, this);
    }
  }

  resetForm() {
    this.formFields.forEach((field) => {
      this.hideError(field);
    });
  }

  showError(field, forceMessage = "") {
    const fieldContainer = field.closest(this.fieldContainerSelector);
    if (!fieldContainer) {
      return;
    }

    const errorContainer = fieldContainer.querySelector(
      this.errorContainerSelector
    );
    if (!errorContainer) {
      return;
    }

    let validationMessage = field.validationMessage || forceMessage;

    if (validationMessage === "") {
      validationMessage = this.form.querySelector(this.trixEditor).dataset['validationMessage'];
    }

    if (this.hasInlineMessageTarget) {
      this.inlineMessageTargets.forEach(inlineMsg => {
        inlineMsg.classList.add('invisible');
      });
    }

    if (this.hasHintTarget) {
      this.hintTargets.forEach(hint => {
        hint.classList.add('invisible');
      });
    }

    errorContainer.innerText = validationMessage;
    fieldContainer.classList.add(this.errorClass);
  }

  hideBaseError(field) {
    this.element.classList.remove(this.baseErrorClass);
  }

  showReplyError(event) {
    event.preventDefault();

    event.target.closest(this.replyContainerSelector).classList.add('hidden');
    this.form.querySelector(this.fieldContainerSelector).classList.remove('hidden');

    let field = this.form.querySelector('#js-form-text-area');

    if (!field) {
      field = this.form.querySelector('[id^="trix_input"]')
    }

    this.showError(field);

    // When we open reply form we also focus on the textarea
    this.form.querySelector('.form-textarea').focus();
  }

  showPendingAttachmentError(event) {
    event.preventDefault();

    this.form.querySelector(this.fieldContainerSelector).classList.remove('hidden');

    this.showError(
      this.getFormField(),
      this.form.querySelector(this.trixEditor).dataset['pendingAttachmentMessage']
    );
  }

  showMaxSizeAttachmentError(event) {
    event.preventDefault();
    this.form.querySelector(this.fieldContainerSelector).classList.remove('hidden');

    this.showError(
      this.getFormField(),
      this.form.querySelector(this.trixEditor).dataset['maxSizeAttachmentMessage']
    );
  }

  getFormField() {
    let field = this.form.querySelector('#js-form-text-area');

    if (!field) {
      field = this.form.querySelector('[id^="trix_input"]');
    }

    return field;
  }

  _resetReplyField(event) {
    let replyContainer = this.form.querySelector(this.replyContainerSelector);

    if (replyContainer) {
      replyContainer.classList.remove('hidden');
      event.target.querySelector(this.fieldContainerSelector).classList.add('hidden');
    }
  }

  showFeedError(event) {
    event.preventDefault();

    let target = event.target

    if (target.nodeName === 'SPAN') {
      target = target.closest('button');
    }

    this.form.querySelector(this.feedContainerSelector).classList.add('hidden');
    target.parentNode.classList.add('hidden');
    this.form.querySelector('#js-hidden-feed-form').classList.remove('hidden');

    let field = this.form.querySelector('#js-form-text-area');

    if (!field) {
      field = this.form.querySelector('[id^="trix_input"]')
    }

    this.showError(field);

    // When we open feed form we also focus on the textarea
    this.form.querySelector('.form-textarea').focus();
  }

  _resetFeedField(event) {
    let feedContainer = this.form.querySelector(this.feedContainerSelector);

    if (feedContainer) {
      feedContainer.classList.remove('hidden');
      event.target.querySelector(this.fieldContainerSelector).classList.add('hidden');
    }
  }

  showAssistantError(event) {
    event.preventDefault();

    let target = event.target

    if (target.nodeName === 'SPAN') {
      target = target.closest('button');
    }

    this.form.querySelector(this.assistantContainerSelector).classList.add('hidden');
    target.parentNode.classList.add('hidden');
    this.form.querySelector('#js-hidden-assistant-form').classList.remove('hidden');

    let field = this.form.querySelector('#js-form-text-area');

    if (!field) {
      field = this.form.querySelector('[id^="trix_input"]')
    }

    this.showError(field);

    // When we open assistant form we also focus on the textarea
    this.form.querySelector('.form-textarea').focus();
  }

  _resetAssistantField(event) {
    let assistantContainer = this.form.querySelector(this.assistantContainerSelector);

    if (assistantContainer) {
      assistantContainer.classList.remove('hidden');
      event.target.querySelector(this.fieldContainerSelector).classList.add('hidden');
    }
  }

  doNotUseBrowserDefaultHTML5Validation() {
    this.form.setAttribute("novalidate", true);
  }

  enableUnobtrusiveJavaScriptElements() {
    // Specifically for handling UJS disabled elements that are
    // disabled on classic form submit (no ajax). The timeout is to
    // cater for the timeout specified in UJS.
    setTimeout(this._enableElement.bind(this), 99);
  }

  _enableElement() {
    enableElement(this.form);
  }

  _dispatchCancelEvent(targetId) {
    PromoteHelpers.dispatchEvent({
      target: document,
      type: 'promote:cancel-changes',
      detail: {
        'id': targetId
      }
    });
  }

  _staticDebouncer(func, wait) {
    let newFn;

    return function(...args) {
      if (newFn) {
        clearTimeout(newFn);
      }

      newFn = setTimeout(() => {
        func.apply(this, args);
      }, wait);
    }
  }

  get formFields() {
    return Array.from(this.form.elements);
  }

  get firstInvalidField() {
    return this.formFields.find((field) => !field.checkValidity() || (field.id.match(/trix_input/g) && /^\s*$/.test(field.value.replace(/(<([^>]+)>)|&nbsp;/ig, ""))));
  }
}
