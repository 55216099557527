import { Controller } from "stimulus";
import { ajax } from "@rails/ujs";

const PREVIOUS = 'previous';
const NEXT = 'next';

export default class extends Controller {
  static targets = ['container', 'filler', 'trigger', 'prevTrigger'];

  connect() {
    if (this.direction == "up") {
      this._scrollToBottom();
    }

    if (!this.hasNextPage && !this.hasPreviousPage) {
      return;
    }

    if (this.hasNextPage) {
      this.nextObserver = this._initializeObserver(this._handleNextPageLoad);
      this.nextObserver.observe(this.triggerTarget);
    }

    if (this.hasPreviousPage) {
      this.previousObserver = this._initializeObserver(this._handlePreviousPageLoad);
      this.previousObserver.observe(this.prevTriggerTarget);
    }
  }

  disconnect() {
    if (this.nextObserver) {
      this.nextObserver.disconnect();
    }

    if (this.previousObserver) {
      this.previousObserver.disconnect();
    }
  }

  _initializeObserver(callback) {
    return new IntersectionObserver(
      callback.bind(this),
      {
        root: document.querySelector(this.data.get("container")),
        rootMargin: "0px",
        threshold: 1.0,
      }
    );
  }

  _handleNextPageLoad(entries) {
    entries.forEach((entry) => {
      if (!entry.intersectionRatio == 1) {
        return;
      }

      if (!this.hasNextPage) {
        this.triggerTarget.classList.add("hidden");
        return;
      }

      this.keepScrollPosition = false;
      this.navigation = NEXT;

      this._loadData(this.nextUrl);
    });
  }

  _handlePreviousPageLoad(entries) {
    entries.forEach((entry) => {
      if (!entry.intersectionRatio == 1) {
        return;
      }

      if (!this.hasPreviousPage) {
        this.prevTriggerTarget.classList.add("hidden");
        return;
      }

      if (this.hasContainerTarget) {
        this._storeInitialPosition();
      }

      this.keepScrollPosition = true;
      this.navigation = PREVIOUS;

      this._loadData(this.previousUrl);
    });
  }

  _loadData(url) {
    ajax({
      url: url,
      type: "get",
      dataType: "script",
      beforeSend: (xhr) => {
        this.ajaxRequest = xhr;
        return true;
      },
      success: () => {
        this._handleScrollTrigger();
        this._keepScrollPosition();
      }
    });
  }

  _handleScrollTrigger() {
    if (this.navigation === NEXT) {
      this._handleObserver(this.nextObserver, this.triggerTarget, this.hasNextPage);
    } else if (this.navigation === PREVIOUS) {
      this._handleObserver(this.previousObserver, this.prevTriggerTarget, this.hasPreviousPage);
    }
  }

  _handleObserver(observer, target, hasPage) {
    if (hasPage) {
      observer.disconnect();
      observer.observe(target);
    } else {
      target.classList.add('hidden');
    }
  }

  _storeInitialPosition() {
    if (this.hasFillerTarget) {
      this.adjustFillerWidth();
    }

    this.previousScrollLeft = this.containerTarget.scrollLeft;
    this.initialWidth = this.containerTarget.scrollWidth;
  }

  adjustFillerWidth() {
    const containerWidth = this.containerTarget.clientWidth;
    const itemsWidth = Array.from(this.containerTarget.children)
      .filter(child => !child.classList.contains('filler'))
      .reduce((total, item) => total + item.offsetWidth, 0);

    const fillerWidth = containerWidth - itemsWidth;
    this.fillerTarget.style.width = `${fillerWidth > 0 ? fillerWidth : 0}px`;
  }

  _keepScrollPosition() {
    if (this.hasContainerTarget && this.keepScrollPosition) {
      const newWidth = this.containerTarget.scrollWidth;
      const newContentWidth = newWidth - this.initialWidth;

      this.containerTarget.scrollLeft = this.previousScrollLeft + newContentWidth;
    }
  }

  _scrollToBottom() {
    this.element.scrollTop = this.element.scrollHeight;

    // We make the trigger target visible outside of the current event loop. This is so that the trigger target does not have the chance to fire the infinite scroll's next page load. The next page load  sets the scrollHeight to be at the next page rather than the bottom, this is not desirable behaviour for the page's initial load where we expect the scroll to the at the bottom.
    //
    // So the solution here is to set the scroll to the bottom in the current event loop and in the next event loop make the trigger target visible so that infinite scroll pagination can occur.
    //
    // Note that this is really hard to test as this undesirable behaviour happens intermittently.
    setTimeout(
      function () {
        this.triggerTarget.classList.remove("hidden");
      }.bind(this),
      1
    );
  }

  get hasNextPage() {
    return !!this.nextUrl;
  }

  get nextUrl() {
    return this.data.get("nextUrl");
  }

  get hasPreviousPage() {
    return !!this.previousUrl;
  }

  get previousUrl() {
    return this.data.get("prevUrl");
  }

  get direction() {
    return this.data.get("direction") || "down";
  }
}
