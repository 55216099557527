import { Controller } from "stimulus";
import { ajax } from "@rails/ujs";

export default class extends Controller {
  static values = { url: String };

  clearDates() {
    const body = new FormData();
    body.append("program_module[start_date_reference_attributes][_destroy]", "1");
    body.append("program_module[end_date_reference_attributes][_destroy]", "1");

    ajax({
      url: this.urlValue,
      type: "PATCH",
      data: body,
      headers: {
        'Content-Type': 'application/json'
      },
      dataType: "script",
      success: (res) => {}
    });
  }
}
