import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["checkbox", "submitButton"];
  static values = {
    modalTitle: String,
    modalMessage: String
  }

  connect() {
    this.editorSubmitBtn = document.querySelector(
      '[data-action="click->editor--form-submit#submit"]'
    );
    this.editorSubmitAndExitBtn = document.querySelector(
      '[data-action="click->editor--form-submit#submitAndExit"]'
    );
    this.submitButtons = [this.submitButtonTarget, this.editorSubmitBtn, this.editorSubmitAndExitBtn];
    this.originallyChecked = this.checkboxTargets
      .filter(checkbox => checkbox.checked)
      .map(checkbox => checkbox.value);
  }

  onChange() {
    this._updateSubmitButton();
  }

  _updateSubmitButton() {
    const currentChecked = this.checkboxTargets
      .filter(checkbox => checkbox.checked)
      .map(checkbox => checkbox.value);

    if (this._isStateChanged(currentChecked)) {
      this.submitButtons.forEach(button => {
        this._setConfirmationAttributes(button);
      });
    } else {
      this.submitButtons.forEach(button => {
        this._removeConfirmationAttributes(button);
      });
    }
  }

  _setConfirmationAttributes(target) {
    target.setAttribute('data-confirm', 'edit-team-form');
    target.setAttribute("data-confirm-title", this.modalTitleValue);
    target.setAttribute('data-confirm-message', this.modalMessageValue);
    target.setAttribute('data-confirm-error', 'true');
  }

  _removeConfirmationAttributes(target) {
    target.removeAttribute("data-confirm");
    target.removeAttribute("data-confirm-title");
    target.removeAttribute("data-confirm-message");
    target.removeAttribute("data-confirm-error");
  }

  _isStateChanged(currentChecked) {
    const uncheckedInitiallyChecked = this.originallyChecked.filter(
      value => !currentChecked.includes(value)
    );

    return uncheckedInitiallyChecked.length > 0;
  }
}
