import { Controller } from 'stimulus';

export default class extends Controller {
  static values = {
    key: String,
    permanent: Boolean,
    static: Boolean
  }
  connect() {
    if (this.keyValue !== "error" && this.permanentValue == false) {
      window.setTimeout(() => {
        this.remove();
      }, 10000);
    }
  }

  remove() {
    if (this.staticValue === true) {
      this.element.classList.add('hidden');
      return false;
    }

    this.element.remove();
  }
}
