import {Controller} from "stimulus";

export default class extends Controller {
  updateUrl(event) {
    let url;

    if (event.currentTarget.classList.contains('disabled')) return;

    if (event.currentTarget.nodeName === "A") {
      url = event.currentTarget.href;
    } else if (event.currentTarget.dataset.url) {
      url = event.currentTarget.dataset.url;
    } else {
      throw new SyntaxError(`href or data-url targets not found for UrlController`);
    }

    window.history.pushState({}, "", url.split("?")[0]);
  }
}
