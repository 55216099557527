import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [ "textArea", 'counter']

  static values = {
    limit: Number
  }

  connect() {
    this._updateRemainingCharacters();
    this.textAreaTarget.addEventListener("input", this._updateRemainingCharacters.bind(this));
  }

  _updateRemainingCharacters(event) {
    this.counterTarget.innerHTML = this.limitValue - this.textAreaTarget.value.length;
  }

}
