import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ['container', 'content'];

  connect() {
    document.addEventListener('promote:open-import-editor', this._openEditor.bind(this));
    document.addEventListener('promote:close-import-editor', this.closeEditor.bind(this));
  }

  disconnect() {
    document.removeEventListener('promote:open-import-editor', this._openEditor.bind(this));
    document.removeEventListener('promote:close-import-editor', this.closeEditor.bind(this));
  }

  closeEditor() {
    this.containerTarget.classList.add('closed');
    this.contentTarget.innerHTML = '';

    document.dispatchEvent(new CustomEvent('promote:import-editor-closed'));

    document.dispatchEvent(new CustomEvent('promote:shortcuts--unregister', {
      detail: { group: 'editor' }
    }));
  }

  highlightBackground() {
    const moduleId = event.currentTarget.dataset.id
    const card = document.getElementById(`import-card-${moduleId}`);

    document.addEventListener('visibilitychange', () => {
      if (!document.hidden) {
        document.dispatchEvent(new CustomEvent('promote:highlight', {
          detail: { element: card }
        }));
      }
    });
  }

  _openEditor() {
    this.containerTarget.classList.remove('initial-state');
    this.containerTarget.classList.remove('closed');

    document.dispatchEvent(new CustomEvent('promote:import-editor-opened'));

    document.dispatchEvent(new CustomEvent('promote:shortcuts--register', {
      detail: {
        key: 'Escape',
        group: 'editor',
        handler: this.closeEditor.bind(this)
      }
    }));
  }
}
