import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ['moveButton']

  static values = {
    forModule: Boolean,
    moveInside: Boolean
  }

  selectMoveMenuItem(event) {
    event.target.closest('.move-menu-content')
      .querySelectorAll('.js-overview-move-menu-item').forEach((item) => {
        item.classList.remove('selected');
      });

    let target = event.target.closest('.overview-move-menu-item');

    target.classList.add('selected');

    this.moveButtonTarget.querySelector('button').disabled = false;

    let baseUrl = this.moveButtonTarget.href.split('?')[0];

    if (this.forModuleValue || this.moveInsideValue) {
      this.moveButtonTarget.href = `${baseUrl}?after_id=${target.dataset.value}`;
    }
    else {
      this.moveButtonTarget.href = `${baseUrl}?module_id=${target.dataset.value}`;
    }
  }
}
