import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ['menu', 'icon','avatar', 'menuItem']

  toggle() {
    if (this._viewportIsMobile()) {
      this.menuTarget.classList.toggle('hidden');
      this.iconTarget.classList.toggle('rotate-90');
      this.iconTarget.classList.toggle('-rotate-90');
    } else {
      this._toggleSubmenu();
      this._displayMenuText();
    }
  }

  toggleUserMenuOnCollapse() {
    this._toggleUserMenu();
    this._hideSubmenu();
    this._displayMenuText();
  }
  _toggleUserMenu() {
    this._toggleAvatar();
    this._toggleIcon();
  }

  _toggleAvatar() {
    this.avatarTargets.forEach(avatar => {
      avatar.classList.toggle('sm:hidden');
    })
  }

  _toggleIcon() {
    this.iconTarget.classList.toggle('sm:hidden');
  }

  _toggleSubmenu() {
    ['hidden', 'flex', 'absolute', 'pt-3', 'ml-5', 'bg-purple-700', 'pr-2',
      'py-1', 'rounded-r-md', 'bottom-5'].forEach(klass => {
      this.menuTarget.classList.toggle(klass);
    })

    this.menuTarget.style = `left: ${this._sideBarWidth()}px;`;
  }

  _hideSubmenu() {
    ['absolute', 'bg-purple-700', 'pr-2',
      'py-1', 'rounded-r-md', 'bottom-5'].forEach(klass => {
      this.menuTarget.classList.remove(klass);
    });

    ['hidden', 'flex', 'pt-3', 'ml-5'].forEach(klass => {
      this.menuTarget.classList.add(klass);
    });
  }

  _displayMenuText() {
    this.menuItemTargets.forEach(item => {
      item.classList.remove('sm:hidden');
    });
  }

  _sideBarWidth() {
    return document.querySelector('.js-sidebar-menu').offsetWidth;
  }

  _viewportIsMobile() {
    return window.innerWidth < 640;
  }
}
