import { Controller } from "stimulus";
import { Draggable } from "@shopify/draggable";
import { ajax } from '@rails/ujs';

export default class extends Controller {
  static values = {
    url: String,
    draggableSelector: String,
    handleSelector: String
  };

  connect() {
    this.draggable = new Draggable(this.element, {
      draggable: this.draggableSelectorValue,
      handle: this.handleSelectorValue
    });

    this.draggable.on('mirror:created', this._onMirrorCreated.bind(this));

    this.draggable.on("drag:move", this._onDragMove.bind(this));

    this.draggable.on("drag:over", this._onDragOver.bind(this));

    this.draggable.on("drag:out", this._onDragOut.bind(this));

    this.draggable.on("drag:stop", this._onDragStop.bind(this));

    this.over = undefined;
  }

  disconnect() {
    this.draggable.destroy();
  }

  _onMirrorCreated(event) {
    const { mirror, _sourceContainer, source, _originalSource } = event.data;

    const dimensions = source.getBoundingClientRect();
    mirror.style.width = `${dimensions.width}px`;
    mirror.style.height = `${dimensions.height}px`;
    mirror.classList.add('z-50');

    const placeholderEl = source.querySelector('.placeholder');
    placeholderEl.style.width = `${dimensions.width - 1}px`; // 1px is the border difference
    placeholderEl.style.height = `${dimensions.height}px`;
    placeholderEl.classList.remove('hidden');
  }

  // FIXME: mostly copy and paste from program_overview_drag_controller.js
  _onDragMove(event) {
    if (!this.over) return

    const sensorData = event.data.sensorEvent.data;
    const crossedBoundaries = this._calculateOverlapPercentage(sensorData);

    if (crossedBoundaries.hasCrossedHalfwayY) {
      this._removeAllDividers();
      this.over.classList.add('divider-below');
      this.before = false;
      this.after = true;
    } else if (event.data.source !== this.over) {
      this.over.classList.remove('divider-below');
      this.over.classList.add('divider-above');
      this.before = true;
      this.after = false;
    }
  }

  _onDragOver(event) {
    if (this.over) this._removeAllDividers();

    this.over = event.data.over;
  }

  _onDragOut(_event) {
    this.over = null;
    this._removeAllDividers();
  }

  _onDragStop(event) {
    const draggedEl = event.data.source;

    if (this.over) {
      if (this.before) {
        this.over.parentNode.insertBefore(draggedEl, this.over);
        this._submitReorder(draggedEl.dataset.id, this.over.dataset.id, 'before');
      } else if (this.after) {
        this.over.parentNode.insertBefore(draggedEl, this.over.nextElementSibling);
        this._submitReorder(draggedEl.dataset.id, this.over.dataset.id, 'after');
      }

      event.data.source.querySelector('.placeholder').classList.add('hidden');
    }

    this._removeAllDividers();
    this.over = null;
    this.before = null;
    this.after = null;
  }

  _removeAllDividers() {
    document.querySelectorAll('.divider-below, .divider-above')
      .forEach((elem) => {
        elem.classList.remove('divider-above')
        elem.classList.remove('divider-below')
      }
    )
  }

  _substitutePlaceholderWithDraggedElement(draggedId) {
    return this.urlValue.replace(':draggable_id', draggedId);
  }

  _submitReorder(draggedId, targetId, placement) {
    const programId = this.programIdValue;
    const url = this._substitutePlaceholderWithDraggedElement(draggedId);
    const body = new FormData();
    let originalElement = document.querySelector('.draggable--original');

    if (placement === 'after') {
      body.append('after_id', targetId);
    } else {
      body.append('before_id', targetId);
    }

    if (this.urlValue) {
      this._performRequest(url, 'PATCH', body, originalElement);
    } else {
      document.dispatchEvent(new CustomEvent('promote:editor-dragend', {
        detail: {
          draggedId,
          targetId,
          placement
        }
      }));

      this._highlightElement(originalElement);
    }
  }

  _performRequest(url, method, body, element) {
    ajax({
      url: url,
      type: method,
      data: body,
      headers: {
        'Content-Type': 'application/json'
      },
      dataType: "script",
      success: () => {
        this._highlightElement(element);
      }
    });
  }

  _highlightElement(el) {
    document.dispatchEvent(new CustomEvent('promote:highlight', {
      detail: { element: el }
    }));
  }

  // FIXME: Copy and paste from program_overview_drag_controller.js
  _calculateOverlapPercentage(event) {
    const { clientX, clientY } = event;
    const overRect = this.over.getBoundingClientRect();

    // Calculate the midpoint of the over element
    const midPointX = overRect.left + overRect.width / 2;
    const midPointY = overRect.top + overRect.height / 2;

    // Check if the mouse is over the top half or bottom half of the over element
    const hasCrossedHalfwayX = clientX > midPointX;
    const hasCrossedHalfwayY = clientY > midPointY;

    return { hasCrossedHalfwayX, hasCrossedHalfwayY };
  }
}
