import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['container', 'averageProgress']

  static values = {
    average: Number
  }

  connect() {
    this._calculate_progression_dot();
  }

  recalculate_dot() {
    this._calculate_progression_dot();
  }

  _calculate_progression_dot() {
    let average = this.averageValue;

    // We are dividing by 16 because the default font size in browsers is 16px
    // So we use this measurement to converts offsetWidth in pixels to rem units
    let containerLengthInRem = this.containerTarget.offsetWidth/16;

    let percentRem = (containerLengthInRem/100) * average;

    // The average progress dot is 0.75rem wide and all calculations are based on this
    if (percentRem < 0.375) {
      this.averageProgressTarget.style.left = '0rem';
    } else if (percentRem > containerLengthInRem - 0.75) {
      this.averageProgressTarget.style.left = `${containerLengthInRem - 0.75}rem`;
    } else {
      this.averageProgressTarget.style.left = `${percentRem - 0.375}rem`;
    }

    this.averageProgressTarget.classList.remove('hidden');
  }
}
