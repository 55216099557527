import { ajax } from '@rails/ujs';
export class TrixEmbed {
  constructor(event) {
    this.editor = event.target.editor;
    this.trixElement = this.editor.element;
    this.toolbar = this.editor.element.toolbarElement;
    this.embedElement = this.toolbar.querySelector("[data-trix-input][name='embedUrl']");
    this.embedContainer = this.toolbar.querySelector("[data-behavior='embed_dialog']");
    this.embedButton = this.toolbar.querySelector("[data-behavior='submit_embed']");
    this.embedIcon = this.toolbar.querySelector("[data-trix-action='x-embed']");
  }

  init() {
    this.initEventListeners();
  }

  initEventListeners() {
    this.embedIcon.addEventListener("click", this.onIconClick.bind(this));
    this.embedButton.addEventListener("click", this.onEmbedClick.bind(this));
  }

  onIconClick() {
    if (this.embedContainer.classList.contains('trix-active')) {
      this.showEmbed();
    } else {
      this.resetEmbed();
    }
  }

  onEmbedClick() {
    const value = this.embedElement.value.trim(),
      regex = /^(https?:\/\/(?:www\.|(?!www))[^\s\.]+\.[^\s]{2,}|www\.[^\s]+\.[^\s]{2,})$/ig;

    if (value.match(regex)) {
      let formData = new FormData();
      formData.append("content", value);

      ajax({
        type: 'PATCH',
        url: `/admin/embeds/`,
        data: formData,
        error: this.resetEmbed.bind(this),
        success: ({content, sgid}) => {
          let attachment = new Trix.default.Attachment({ sgid: sgid, content: content });
          let range = this.editor.getSelectedRange();
          this.editor.insertAttachment(attachment);
          this.editor.setSelectedRange(range);
          this.editor.insertLineBreak();
          this.resetEmbed();
        }
      })
    }
  }

  resetEmbed() {
    this.embedElement.value = "";
    this.embedContainer.classList.add('hidden');
    this.editor.element.focus();
  }

  showEmbed() {
    this.embedElement.removeAttribute("disabled");
    this.embedContainer.classList.remove('hidden');
    this.embedElement.focus();
  }
}
