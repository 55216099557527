window.addEventListener("turbolinks:load", function () {
  // IE <= 10 => MSIE. IE 11 => Trident
  if (navigator.userAgent.indexOf("Trident") != -1) {
    var warningEl = document.getElementById("old-browser-warning");

    if (typeof warningEl !== "undefined") {
      warningEl.setAttribute(
        "class",
        warningEl.getAttribute("class").replace(/hidden/, "")
      );
    }
  }
});
