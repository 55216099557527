import { Controller } from "stimulus";
import { ajax } from '@rails/ujs';

export default class extends Controller {
  static outlets = ['overview--toggle-info'];

  sync(programId, contentPageId) {
    if (this.ajaxRequest) {
      this.ajaxRequest.abort(); // Abort previous ajax request.
    }

    ajax({
      url: this._syncUrl(programId, contentPageId),
      type: "GET",
      dataType: "script",
      beforeSend: (xhr) => {
        this.ajaxRequest = xhr;
        return true;
      },
      success: () => {
        this.overviewToggleInfoOutlet.toggleOptionDataForContentPage(contentPageId);
      },
    })
  }

  _syncUrl(programId, contentPageId) {
    return `/admin/programs/${programId}/overview/content_pages/${contentPageId}/sync`;
  }
}
