import { Controller } from "stimulus";
import Rails from '@rails/ujs';

export default class extends Controller {
  static values = {
    confirmMessage: String,
    confirmTitle: String,
    confirmAllow: String,
    confirmCancel: String
  }

  connect() {
    addEventListener("beforeunload", this.beforeUnload.bind(this));
  }

  disconnect() {
    removeEventListener("beforeunload", this.beforeUnload.bind(this));
  }

  async beforeUnload(event) {
    const anyPendingForms = await this._hasPendingForms();
    if (!anyPendingForms) {
      return;
    }

    event.preventDefault();
    event.returnValue = '';
  }

  async verify() {
    const anyPendingForms = await this._hasPendingForms();

    const confirmation = await new Promise((resolve, reject) => {
      if (anyPendingForms) {
        Rails.confirm(null, this.element, this._i18nMessages(), {
          confirmCallback: resolve, cancelCallback: reject
        })
      } else {
        resolve();
      }
    });

    return confirmation
  }

  _i18nMessages() {
    return {
      confirmAllow: this.confirmAllowValue,
      confirmCancel: this.confirmCancelValue,
      confirmTitle: this.confirmTitleValue,
      confirmMessage: this.confirmMessageValue
    }
  }

  // This method will race through all forms stimulus controllers with the
  // form-errors controller and check if any of them have a pending state via
  // their async method "check". As soon as one of the forms has a pending
  // state, it will return true, otherwise it will return false.
  async _hasPendingForms() {
    const forms = this.application.controllers.filter(controller => {
      return controller.identifier === "form-errors";
    });

    const pendingForms = await Promise.all(forms.map(form => form.anyPendingForms()));

    return pendingForms.some(pending => pending);
  }
}
