const AVAILABLE_ATTRS = [
  'bold', 'italic', 'href',
  'quote', 'bullet', 'number',
  'decreaseNestingLevel', 'increaseNestingLevel',
  'alignLeft', 'alignRight', 'alignCenter',
  'heading1', 'heading2', 'heading3'
];

export class TrixEditor {
  constructor(event) {
    this.editor = event.target.editor;
    this.trixElement = this.editor.element;
    this.toolbar = this.editor.element.toolbarElement;
    this.dialogs = this.toolbar.querySelector("[data-trix-dialogs]");
    this.cleanButton = this.toolbar.querySelector("[data-trix-action='x-clean']");
    this.horizonalRuleButton = this.toolbar.querySelector("[data-trix-action='x-horizontal-rule']");
    this.formattingIcons = this.findTrixToolbarButtons.filter(icon => this.standardToolbarIcons(icon));
  }
  init() {
    this.initEventListeners();
  }

  initEventListeners() {
    this.cleanButton.addEventListener("click", this.onCleanClick.bind(this));
    this.horizonalRuleButton.addEventListener("click", this.onHorizontalRuleClick.bind(this));
    this.formattingIcons.forEach(icon => icon.addEventListener('click', this.onTrixToolbarIconClick.bind(this)));
    this.trixElement.addEventListener('trix-selection-change', this.onTrixSelection.bind(this));
    this.trixElement.addEventListener('trix-change', this.onTrixChange.bind(this));
    this.trixElement.addEventListener('trix-focus', this.onTrixFocus.bind(this));
  }

  onTrixChange() {
    this.findActiveAttributes.forEach(attr => this.toggleActiveAttributes(attr));

    this.findHistoryAttributes.forEach(el => this.toggleHistoryState(el));
  }

  toggleHistoryState(historyEl) {
    let currentPosition = this.editor.getDocument().toString().length - 1;

    this.updateHistoryIconState(currentPosition, historyEl);
  }

  onTrixFocus() {
    this.resetTrixDialogs();
  }

  onTrixSelection() {
    let alignmentButton = this.toolbar.querySelector("[data-trix-action='x-alignment']");
    if (alignmentButton) alignmentButton.innerHTML = this.defaultSVGIcon()

    AVAILABLE_ATTRS.forEach(attr => this.toggleActiveAttributes(attr));
  }

  onTrixToolbarIconClick(event) {
    let el = event.currentTarget;

    this.toggleActiveIcons(el, this.editor.attributeIsActive(el.dataset.trixAttribute));
  }

  onHorizontalRuleClick() {
    const attachment = new Trix.default.Attachment({
      content: '<hr>', contentType: "vnd.rubyonrails.horizontal-rule.html"
    })
    this.editor.insertAttachment(attachment);
  }

  onCleanClick() {
    let selectedRange = this.editor.getSelectedRange();
    let selectedText = this.editorDocument.getStringAtRange(selectedRange);

    Array.from(AVAILABLE_ATTRS).forEach((attr) => {
      if (this.editor.attributeIsActive(attr)) this.editor.deactivateAttribute(attr)

      if (selectedText.length === 0) {
        this.clearActivePieces(attr);
      }
      else {
        this.editor.setSelectedRange(selectedRange);
        this.clearActiveSelection(attr);
      }
    })
  }

  clearActiveSelection(attr) {
    this.editor.deactivateAttribute(attr);
    this.resetCustomIcons(attr);
    this.editor.element.focus();
  }

  clearActivePieces(attr) {
    const activePieces = this.findActiveBlockAttributes.filter(piece => piece.hasAttribute(attr));

    if (activePieces.length > 0) {
      Array.from(activePieces).forEach(piece => {
        const pieceLength = piece.length;
        const pieceText = piece.toString();
        const documentText = this.editor.getDocument().toString();
        const firstIndexOfPiece = documentText.indexOf(pieceText);
        const lastIndexOfPiece = documentText.lastIndexOf(pieceText);
        const startPosition = firstIndexOfPiece == lastIndexOfPiece ? lastIndexOfPiece : firstIndexOfPiece;
        const endPosition = lastIndexOfPiece + pieceLength;
        this.editor.setSelectedRange([startPosition, endPosition]);

        const multipleActivePieceAttributes = piece.attributes.array.filter(attribute => attribute[0]);
        // If the piece has more then one active attribute, iterate over and deactivate each of them
        if (multipleActivePieceAttributes.length >= 1) {
          multipleActivePieceAttributes.forEach(attribute => this.editor.deactivateAttribute(attribute))
        }
        else {
          // Otherwise, only deactivate the current attribute
          this.editor.deactivateAttribute(attr);
        }

        this.clearActiveIcons(attr);
      })
    }

    this.editor.setSelectedRange(this.editorPosition);
    this.resetCustomIcons(attr);
    this.editor.element.focus();
  }

  clearActiveIcons(attr) {
    const attributeElements = this.toolbar.querySelectorAll(`[data-trix-attribute="${attr}"]`);

    if (attributeElements.length > 0) {
      const attributeClasses = ['selected', 'data-trix-selected', 'data-trix-active'];

      Array.from(attributeElements).forEach((el) => {
        const strokes = el.querySelectorAll('.trix-stroke');

        el.classList.remove("trix-active", "trix-selected");
        attributeClasses.forEach(trixAttr => el.removeAttribute(`${trixAttr}`));
        strokes.forEach(strokeEl => strokeEl.classList.remove('trix-active-stroke'));
      })
    }
  }

  resetCustomIcons(attr) {
    if (this.hasActiveHeading(attr)) {
      let headerElement = this.toolbar.querySelector("[data-behavior='header_selection']");
      if (headerElement) {
        headerElement.text = "Normal";
        headerElement.style.color = "#444";
      }
    }

    if (this.hasActiveAlignment(attr)) {
      let alignmentButton = this.toolbar.querySelector("[data-trix-action='x-alignment']");
      if (alignmentButton) {
        alignmentButton.classList.remove('open');
        this.toggleActiveIcons(alignmentButton, false);
      }
    }
  }

  resetTrixDialogs() {
    const trixDialogs = Array.from(this.dialogs.children);

    if (trixDialogs.length >= 1) trixDialogs.forEach(el => el.classList.add('hidden'))
  }

  toggleActiveAttributes(attr) {
    let attrElements = Array.from(this.toolbar.querySelectorAll(`[data-trix-attribute="${attr}"]`));

    if (attrElements.length >= 1) {
      attrElements.forEach(el => this.applyActiveSelection(el, attr))
    }
  }

  applyActiveSelection(el, attr) {
    if (this.editor.attributeIsActive(attr)) {
      this.updateIconStates(el, true);
    }
    else {
      this.toggleActiveIcons(el, false);
    }
  }

  updateIconStates(activeElement, activeState) {
    const strokeElements = activeElement.querySelectorAll('.trix-stroke');

    if (strokeElements) this.toggleIconState(strokeElements, activeState);
    if (activeElement.nodeName === 'SPAN') this.updateIconSVG(activeElement)
  }

  toggleIconState(strokeElements, strokeState) {
    if (strokeElements && strokeElements.length >= 1) {
      Array.from(strokeElements).forEach(el => {
        el.classList.toggle('trix-active-stroke', strokeState)
      })
    }
  }

  toggleActiveIcons(el, state) {
    let strokeElements = el.querySelectorAll('.trix-stroke');

    if (strokeElements.length >= 1) {
      Array.from(strokeElements).forEach(el => {
        el.classList.toggle('trix-active-stroke', state)
      })
    }
  }

  updateHistoryIconState(position, el) {
    if (position === 0) {
      el.classList.toggle('disabled', !this.editor.canRedo())
    }
    else {
      if (this.canPerformHistoryAction()) el.classList.remove('disabled')
    }
  }

  canPerformHistoryAction() {
    return this.editor.canUndo() || this.editor.canRedo()
  }

  updateIconSVG(activeElement) {
    let alignmentButton = this.toolbar.querySelector("[data-trix-action='x-alignment']");

    if (alignmentButton) {
      alignmentButton.innerHTML = this.alignmentIconSVG(activeElement)
    }
  }

  alignmentIconSVG(activeElement) {
    return this.hasActiveAlignment(activeElement.dataset.trixAttribute) ? activeElement.innerHTML : this.defaultSVGIcon()
  }

  hasCustomActions(action) {
    return [ 'x-clean', 'x-alignment', 'x-horizontal-rule' ].includes(action)
  }

  hasActiveAlignment(attr) {
    return [ 'alignLeft', 'alignCenter', 'alignRight' ].includes(attr)
  }

  hasActiveHeading(attr) {
    return [ 'heading1', 'heading2', 'heading3' ].includes(attr)
  }

  standardToolbarIcons(el) {
    return this.hasCustomActions(el.dataset.trixAction) == false
  }

  defaultSVGIcon() {
    return `<svg viewBox="0 0 18 18">
      <line class="trix-stroke" x1="3" x2="15" y1="9" y2="9"></line>
      <line class="trix-stroke" x1="3" x2="13" y1="14" y2="14"></line>
      <line class="trix-stroke" x1="3" x2="9" y1="4" y2="4"></line>
    </svg>`
  }

  get findActiveAttributes() {
    return AVAILABLE_ATTRS.filter(attribute => this.editor.attributeIsActive(attribute))
  }

  get findHistoryAttributes() {
    return Array.from(this.toolbar.querySelectorAll('.trix-button-group--history-tools button'))
  }

  get findActiveBlockAttributes() {
    return Array.from(this.blockPieceAtCursor.text.getPieces())
  }

  get findTrixToolbarButtons() {
    return Array.from(this.toolbar.querySelectorAll('.trix-button'))
  }

  get editorDocument() {
    return this.editor.getDocument()
  }

  get editorPosition() {
    return this.editor.getPosition()
  }

  get blockPieceAtCursor() {
    return this.editorDocument.getBlockAtPosition(this.editorPosition)
  }
}
