import { Controller } from 'stimulus';

/*
  This controller is responsible for handling keyboard shortcuts. It provides
  an interface based on custom events to register and unregister them.

  Events:
    - promote:shortcuts--register
      - key (string): the key to listen for
      - group (string): the group to which the shortcut belongs
      - handler (function): the function to call when the shortcut is triggered
      - options (object): additional configuration options. Currently supports:
        - prepend (boolean): whether the shortcut will take precedence over
                             others in the case of a conflict or unitentional
                             triggering, for example when a dialog is on top of
                             a window with shortcuts (default: false)
    - promote:shortcuts--unregister
      - group (string): the group to which the shortcuts belong. All shortcuts
                        in the group are removed

  Examples:
    document.dispatchEvent(new CustomEvent('promote:shortcuts--register', {
      detail: {
        key: 'Escape',
        group: 'some-window',
        handler: closeSomeWindow.bind(this),
      }
    }));
    document.dispatchEvent(new CustomEvent('promote:shortcuts--unregister', {
      detail: {
        group: 'some-window'
      }
    }));

  Note: modals and dialogs should register and unregister their shortcuts when
  they open and close to avoid conflicts.
*/
export default class extends Controller {
  connect() {
    this.shortcuts = [];

    document.addEventListener('promote:shortcuts--register', this._registerShortcut.bind(this));
    document.addEventListener('promote:shortcuts--unregister', this._unregisterGroup.bind(this));
    document.body.addEventListener('keydown', this._handleShortcut.bind(this));
  }

  disconnect() {
    document.removeEventListener('promote:shortcuts--register', this._registerShortcut.bind(this));
    document.removeEventListener('promote:shortcuts--unregister', this._unregisterGroup.bind(this));
    document.body.removeEventListener('keydown', this._handleShortcut.bind(this));
  }

  _registerShortcut(event) {
    const { key, group, handler, options } = event.detail;
    const shortcut = {
      key: key,
      group: group,
      handler: handler,
      options: options || {}
    };

    this.shortcuts.forEach((shortcut, index) => {
      if (shortcut.key === key && shortcut.group == group) this.shortcuts.splice(index, 1);
    });

    shortcut.options.prepend ? this.shortcuts.unshift(shortcut) : this.shortcuts.push(shortcut);
  }

  _unregisterGroup(event) {
    this.shortcuts.forEach((shortcut, index) => {
      if (shortcut.group === event.detail.group) this.shortcuts.splice(index, 1);
    });
  }

  _handleShortcut(event) {
    const matchingShortcut = this.shortcuts.find((shortcut) => shortcut.key === event.key);

    if (!matchingShortcut) return;

    event.preventDefault();
    event.stopPropagation();
    matchingShortcut.handler(event);
  }
}
