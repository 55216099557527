import { Controller } from "stimulus";
import {ajax} from "@rails/ujs";

let debounce = require('lodash/debounce');

export default class extends Controller {
  static targets = ['input', 'closeIcon'];

  static values = {
    url: String
  }

  connect() {
    this.onInput = debounce(this._filterOnInput, 300).bind(this);
    this.inputTarget.addEventListener('input', this.onInput);
    this.closeIconTarget.addEventListener('click', this._resetInputField.bind(this));
  }

  disconnect() {
    this.inputTarget.removeEventListener('input', this.onInput);
    this.closeIconTarget.removeEventListener('click', this._resetInputField.bind(this));
  }

  _filterOnInput() {
    if (this.inputTarget.value === '') {
      this.closeIconTarget.classList.add('hidden');
    } else {
      this.closeIconTarget.classList.remove('hidden');
    }

    if (this.urlValue !== '') {
      this._loadData();
    }
  }
  _resetInputField() {
    this.closeIconTarget.classList.add('hidden');
    this.inputTarget.value = '';

    if (this.urlValue !== '') {
      this._loadData();
    }
  }

  _loadData(callback) {
    if (this.ajaxRequest) {
      this.ajaxRequest.abort(); // Abort previous ajax request.
    }

    ajax({
      url: this._url(),
      type: "GET",
      dataType: "script",
      beforeSend: (xhr) => {
        this.ajaxRequest = xhr;
        return true;
      },
      success: () => {
        if (callback) callback();
      }
    })
  }

  _url() {
    if (this.urlValue.match(/\?/)) {
      return `${this.urlValue}&search=${encodeURIComponent(this.inputTarget.value)}`;
    } else {
      return `${this.urlValue}?search=${encodeURIComponent(this.inputTarget.value)}`;
    }
  }
}
