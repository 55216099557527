import { Controller } from "stimulus";
import {ajax} from "@rails/ujs";

export default class extends Controller {

  static values = {
    url: String
  };

  connect() {
    this._loadData();
  }

  _loadData() {
    if (this.ajaxRequest) {
      this.ajaxRequest.abort(); // Abort previous ajax request.
    }

    ajax({
      url: this.urlValue,
      type: 'GET',
      dataType: 'script',
      beforeSend: (xhr) => {
        this.ajaxRequest = xhr;
        return true;
      }
    })
  }
}
