import { ajax } from '@rails/ujs';
import './init_trix_toolbar.js';

export class RichTextEditor {
  constructor(element) {
    this.element = element;
    this.setupTrixEditor();
  }

  setupTrixEditor = () => {
    this.defineCustomAttributes();
    this.initializeElements();
    this.installEvents();
    this.reset();
  }

  defineCustomAttributes = () => {
    Trix.default.config.textAttributes.primaryColor = {
      tagName: 'primary-color', nestable: false, exclusive: true
    }
  }

  initializeElements = () => {
    this.editor = this.element.editor;
    this.toolbar = this.editor.element.toolbarElement;
    this.embedElement = this.toolbar.querySelector("[data-behavior='embed_url']");
    this.hrefElement = this.toolbar.querySelector("[data-trix-input][name='href']");
    this.embedContainerElement = this.toolbar.querySelector("[data-behavior='embed_container']");
  }

  installEvents = () => {
    this.embedElement.addEventListener("click", this.embed.bind(this));
    this.hrefElement.addEventListener("input", this.didInput.bind(this));
  }

  didInput(event) {
    const value = event.target.value.trim();
    // Load patterns from server so we can dynamically update them
    if (this.patterns === undefined) {
      this.loadPatterns(value);

      // When patterns are loaded, we can just fetch the embed code
    } else if (this.match(value)) {
      this.fetch(value);

      // No embed code, just reset the form
    } else {
      this.reset();
    }
  }

  loadPatterns(value) {
    ajax({
      type: "get",
      url: "/embeds/patterns.json",
      success: (response) => {
        this.patterns = response.map(
          (pattern) => new RegExp(pattern.source, pattern.options)
        );
        if (this.match(value)) {
          this.fetch(value);
        }
      },
    });
  }

  // Checks if a url matches an embed code format
  match(value) {
    return this.patterns.some((regex) => regex.test(value));
  }

  fetch(value) {
    ajax({
      url: `/embeds?id=${encodeURIComponent(value)}`,
      type: "post",
      dataType: 'json',
      error: this.reset.bind(this),
      success: this.handleSuccess.bind(this),
    });
  }

  handleSuccess(result) {
    if (result.error) {
      this.reset();
    }
    else {
      this.showEmbed(result);
    }
  }

  showEmbed(embed) {
    this.currentEmbed = embed;
    this.embedContainerElement.style.display = "block";
  }

  reset() {
    this.embedContainerElement.style.display = "none";
    this.currentEmbed = null;
  }

  embed(event) {
    if (this.currentEmbed == null) {
      return;
    }

    const attachment = new Trix.default.Attachment(this.currentEmbed);
    this.editor.insertAttachment(attachment);
    this.element.focus();
  }
}
