// regex for matching pasted links
const EMAIL_REGEX = /\S+@\S+\.\S+/ig;
const URL_REGEX = /(https?:\/\/\S+\.\S+)\S/ig;
// regex for matching typed links via input and dialog
const URL_PATTERN = /^(https:\/\/|http:\/\/|www)?\S+\.\S+\S$/ig;
const EMAIL_PATTERN = /^\S+@\S+\.\S+\S$/;
// constants for identifying link types
const EMPTY_MARKERS = [
  "", undefined, "https://", "mailto:"
];
const MISSING_TYPE = 'MISSING_LINK';
const EMAIL_TYPE = 'EMAIL_LINK';
const URL_TYPE = 'URL_LINK';

export class TrixLink {
  constructor(event) {
    this.editor = event.target.editor;
    this.trixElement = this.editor.element;
    this.toolbar = this.trixElement.toolbarElement;
    this.linkElement = this.toolbar.querySelector("[data-trix-action='link']");
    this.linkSubmitBtn = this.toolbar.querySelector("[data-trix-method='setAttribute']");
    this.linkRemoveBtn = this.toolbar.querySelector("[data-trix-method='removeAttribute']");
    this.linkInput = this.toolbar.querySelector('[data-trix-input][name="href"]');
  }

  init() {
    this.initEventListeners();
  }

  initEventListeners() {
    this.trixElement.addEventListener("trix-paste", this.onTrixPaste.bind(this));
    this.trixElement.addEventListener("keydown", this.onTrixKeyPress.bind(this));
    this.linkRemoveBtn.addEventListener('click', this.onLinkRemove.bind(this));
    this.linkSubmitBtn.addEventListener('click', this.onLinkSubmit.bind(this));
  }

  onLinkRemove () {
    const range = this.editor.getSelectedRange();
    const attrs = this.editorDocument.getCommonAttributesAtRange(range);

    if (attrs.hasOwnProperty('frozen') || this.editor.attributeIsActive('frozen')) {
      this.editor.deactivateAttribute('frozen');
      this.editor.setSelectedRange(range);
    }

    this.editor.deactivateAttribute('href');
  }

  onLinkSubmit(event) {
    const linkValue = this.linkInput.value.trim(),
      linkType = this.parseLinkType(linkValue);

    if (this.validLink(linkType)) {
      this.linkInput.value = this.formatLink(linkValue, linkType);
      this.linkInput.type = linkType == URL_TYPE ? 'url' : 'text';
    }
    else {
      event.preventDefault();
      this.linkInput.classList.add('trix-validate');
      this.linkInput.setAttribute('data-trix-validate', '');
    }
  }

  parseLinkType(link) {
    if (EMPTY_MARKERS.includes(link)) { return MISSING_TYPE }
    else if (this.emailLink(link))    { return EMAIL_TYPE }
    else if (this.urlLink(link))      { return URL_TYPE }
  }

  validLink(linkType) {
    return [ EMAIL_TYPE, URL_TYPE ].includes(linkType)
  }

  emailLink(input) {
    return input.match(EMAIL_PATTERN) || input.includes("mailto:");
  }

  urlLink(input) {
    if (input.includes("@")) { return false }

    return this.formatUrl(input).match(URL_PATTERN);
  }

  formatLink(linkValue, linkType) {
    switch (linkType) {
      case EMAIL_TYPE:
        return this.formatEmail(linkValue);
      case URL_TYPE:
        return this.formatUrl(linkValue);
      default:
        return linkValue;
    }
  }

  formatEmail(email) {
    return email.startsWith('mailto:') ? email : `mailto:${email}`
  }

  formatUrl(url) {
    return url.startsWith('http') ? url : `https://${url}`
  }

  onTrixKeyPress(event) {
    if (event.code == "Space") {
      let position = this.editor.getPosition();
      let documentStr = this.editor.getDocument().toString();

      // In order to not just grab the last position of the array, we need to
      // grab the last word before the cursor position starting from the
      // beginning; this will match anything, from the beginning of the block
      // in the middle or at the end.
      let content = documentStr.slice(0, position).replace(/\n/g, ' ').trim()
        .split(" ").at(-1);

      if (content.match(EMAIL_PATTERN)) {
        this.activateLink(content, this.formatEmail(content))
      } else if (content.match(URL_PATTERN)) {
        this.activateLink(content, this.formatUrl(content))
      }
    }
  }

  activateLink(content, href) {
    const position = this.editorDocument.toString().lastIndexOf(content);
    const range = [position, position + content.length];

    this.updateInRange(this.editor, range, 0, () => {
      if (this.editor.canActivateAttribute('href'))
        this.editor.activateAttribute('href', href)
    })
  }

  onTrixPaste(event) {
    if (event.paste.hasOwnProperty('html')){
      let content = event.target.innerText || this.editorDocument.toString();

      if (content.length > 1) {
        if (!!content.match(URL_REGEX)) {
          this.autoLink(URL_REGEX, content);
        }

        if (!!content.match(EMAIL_REGEX)) {
          this.autoLink(EMAIL_REGEX, content);
        }

        this.editor.recordUndoEntry("Auto Link Paste");
        event.paste.html = this.editor.element.innerHTML;
      }
    }
  }

  autoLink(pattern, content) {
    let match;
    const regex = new RegExp(pattern, 'igm');

    while ((match = regex.exec(content))) {
      const link = match[0].trim();
      const position = match.index;
      const range = [position, position + link.length];
      const hrefAtRange = this.editorDocument.getCommonAttributesAtRange(range).href;
      const newLink = link.slice(0, link.length);

      if (hrefAtRange !== newLink) {
        const linkHref = newLink.startsWith('http') ? newLink : `mailto:${newLink}`;

        this.updateInRange(this.editor, range, 0, () => {
          if (this.editor.attributeIsActive('href', linkHref)) { return; }

          if (this.editor.canActivateAttribute('href')) {
            this.editor.activateAttribute('href', linkHref);
          }
        });
      }
    }
  }

  // trix helper that applies func in range then restores base range when done
  updateInRange(editor, range, offset = 0, updateFunc) {
    const baseRange = editor.getSelectedRange();
    editor.setSelectedRange(range);
    updateFunc();
    editor.setSelectedRange([baseRange[0] + offset, baseRange[1] + offset]);
  }

  get editorDocument() {
    return this.editor.getDocument()
  }
}
