import {Controller} from "stimulus";

export default class extends Controller {
  static targets = ["input", "listItem", "icon"];

  select(event) {
    if (event.target.classList.contains('js-mobile-check-icon')) {
      return false;
    }

    this.inputTarget.value = event.currentTarget.dataset["id"];

    this._updateActiveItem(event.target);
  }

  _updateActiveItem(target) {
    if (target.nodeName === 'SPAN') {
      target = target.closest('div')
    }

    this._removeFontColorMobile();
    this._setFontColorMobile(target);
    this._updateIconPlacement(target);
  }

  _removeFontColorMobile() {
    this.listItemTargets.forEach(item => {
      item.classList.remove('font-bold', 'text-primary-700');
    })
  }

  _setFontColorMobile(target) {
    target.classList.add('font-bold', 'text-primary-700');
  }

  _updateIconPlacement(target) {
    let mobileIcon = this.iconTarget.cloneNode(true);

    this.iconTarget.remove();
    target.closest('div').appendChild(mobileIcon);
  }
}
