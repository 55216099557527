import { Controller } from "stimulus";

export default class extends Controller {
  connect() {
    let elementToHighlight = document.querySelector('.highlight');

    if (elementToHighlight) {
      this._scrollElementIntoView(elementToHighlight);
      this._removeHighlight(elementToHighlight);
    }

    document.addEventListener('promote:highlight', this._onHighlightElement.bind(this));
    document.addEventListener('promote:highlight-added', this._onHighlightAdded.bind(this));
  }

  disconnect() {
    document.removeEventListener('promote:highlight', this._onHighlightElement.bind(this));
    document.removeEventListener('promote:highlight-added', this._onHighlightAdded.bind(this));
  }

  _onHighlightElement(event) {
    const element = event.detail.element;

    element.classList.add('highlight');

    this._removeHighlight(element);
  }

  _onHighlightAdded(event) {
    const element = event.detail.element;

    this._removeHighlight(element);
  }

  _removeHighlight(element) {
    setTimeout(() => {
      element.classList.remove('highlight');
    }, 5000);
  }

  _scrollElementIntoView(elem) {
    if (elem.getBoundingClientRect().right > window.innerWidth) {
      elem.scrollIntoView({ behavior: "instant", block: 'nearest', inline: "start"});
    }
  }
}
