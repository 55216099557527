var PromoteHelpers = {
  formDataWithCSRF: function() {
    var formData = new FormData();
    var csrfParam = document.querySelector("meta[name=\"csrf-param\"]");
    var csrfValue = document.querySelector("meta[name=\"csrf-token\"]");
    if (csrfParam && csrfValue) {
      formData.append(csrfParam.content, csrfValue.content);
    }

    return formData;
  },

  findFirstParent: function(element, selector) {
    for(var el = element.parentElement; el !== document.body; el = el.parentElement) {
      if(el.matches(selector))
        {return el;}
    }

    return null;
  },

  addFilteredEventListener: function(el, event, selector, handler) {
    el.addEventListener(event, function(ev) {
      if (ev.target.matches(selector)) {
        ev.preventDefault();
        ev.stopPropagation();
        handler(ev);
        return false;
      }
    });
  },

  // opts:
  //   - target: [Node] event target
  //   - type: [str] event type
  //   - detail: [Object] event detail - optional
  dispatchEvent: function(opts) {
    var evt = document.createEvent("CustomEvent");
    evt.initCustomEvent(opts.type, true, true, opts.detail);
    opts.target.dispatchEvent(evt);
  },

  listenToTaskNavigationEvent: function(callback) {
    var programContainer = document.getElementById("js-program");
    if(programContainer) programContainer.addEventListener("taskNavigation", callback);
  },

  getDomainFromUri: function(url) {
    var matches = url.match(/^https?\:\/\/([^\/?#]+)(?:[\/?#]|$)/i);
    return matches && matches[1];
  },

  checkIfImageExists: function (image_url) {
    return new Promise(function(resolve, reject) {
        var img = new Image();
        img.src = image_url;
        img.onload = resolve;
        img.onerror = reject;
    });
  },

  replaceBrokenImageWithErrorMsg: function (editor, uuid, errorMessage) {
      var content = editor.getContents()["ops"];

      for (var j = 0; j < content.length; j++) {
        var delta = content[j];

        if (delta.insert.image && delta.insert.image.indexOf(uuid) > -1) {
            content[j] = { insert: { "error-msg": { text: errorMessage, classNames: ["-img-upload"] } } };
        }
      }

      editor.setContents(content);
  }
};

export { PromoteHelpers as default };
