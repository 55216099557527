import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ['tab', 'content', 'searchInput'];

  switchTab(event) {
    let tabTarget = event.target;

    if (tabTarget.nodeName !== 'LI') {
      tabTarget = tabTarget.closest('li');
    }

    if (tabTarget.classList.contains('disabled')) return;

    this._changeTabStatus();
    this._showTargetContent(tabTarget);

    tabTarget.classList.add('active');
  }

  _changeTabStatus() {
    this.tabTargets.forEach((tab) => {
      if (tab.classList.contains('active')) {
        tab.classList.remove('active');
        this._forceTabReload(tab);
      }
    });
  }

  _showTargetContent(tabTarget) {
    let contentId = tabTarget.dataset.contentId;

    this.contentTargets.forEach((content) => {
      if (content.id === contentId) {
        content.classList.remove('hidden');
      } else {
        content.classList.add('hidden')
      }
    });
  }

  _forceTabReload(tab) {
    if (this.hasSearchInputTarget && this.searchInputTarget !== '') {
      // Force tab to reload if accessed again
      let contentId = tab.dataset.contentId;
      let contentEl = document.getElementById(contentId);
      contentEl.dataset.contentForceReload = true;
    }
  }
}
