import { Controller } from "stimulus";
import { ajax } from "@rails/ujs";

export default class extends Controller {
  static outlets = ['checkbox-select-all'];

  static targets = [
    'numberInput'
  ];

  static values = {
    url: String
  };

  connect() {
    this.numberInputTarget.
      addEventListener("change", this.extractOffset.bind(this));

    this.numberInputTarget.
      addEventListener("blur", this.extractOffset.bind(this));
  }

  disconnect() {
    this.numberInputTarget.
      removeEventListener("change", this.extractOffset.bind(this));

    this.numberInputTarget.
      removeEventListener("blur", this.extractOffset.bind(this));
  }

  extractOffset(event) {
    event.preventDefault();

    const taskIds = this.grabTaskIds();

    if (!taskIds) {
      return;
    }

    // The detail property is defined when a calendar sync happens, it triggers
    // an change event. When the number component itself changes, it triggers a
    // blur event.
    const offset = event?.detail?.value || event.target.value;
    this.bulkUpdateDueDates(taskIds, offset);
  }

  grabTaskIds() {
    const checkedTasks = this.checkboxSelectAllOutlet.checked;

    if (!checkedTasks.length) {
      return;
    }

    return checkedTasks.map((checkbox) => {
      return checkbox.value;
    });
  }

  clearDates() {
    this.numberInputTarget.dispatchEvent(new CustomEvent('change', { detail: { value: '' } }));
    const dateInput = this.element.querySelector(
      '[data-date-management--bulk-update-target="dateInput"]'
    );

    if (dateInput) {
      dateInput.dispatchEvent(
        new CustomEvent('promote:datepicker-change', { detail: { value: '' } })
      );
    }

    const taskIds = this.grabTaskIds();

    if (!taskIds) {
      return;
    }

    this.bulkUpdateDueDates(taskIds, '');
  }

  bulkUpdateDueDates(taskIds, offset) {
    const body = new FormData();

    if (offset === '' || offset === undefined) {
      body.append("task[date_reference_attributes][_destroy]", true);
    } else {
      body.append("task[date_reference_attributes][offset]", offset + ' days');
      body.append("task[date_reference_attributes][anchor]", 'start_at');
    }

    taskIds.forEach((taskId) => {
      body.append("task_ids[]", taskId);
    });

    ajax({
      url: this.urlValue,
      type: 'PATCH',
      data: body,
      headers: {
        'Content-Type': 'application/json'
      },
      dataType: "script",
      success: (res) => {}
    });
  }
}
