import { TrixImage } from "./trix_image";

export class TrixImageResize {

  constructor(event) {
    this.trixElement = event.target;
  }

  init() {
    this.trixElement.addEventListener('trix-selection-change', this.addEventListeners.bind(this));
  }

  addEventListeners(event) {
    const trixAttachment = this.trixElement.querySelector('figure[data-trix-mutable="true"]');

    if (trixAttachment) {
      const trixImage = new TrixImage(event, trixAttachment);
      trixImage.init();
    }
  }
}
