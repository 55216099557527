import Trix from 'trix';
import "./trix/alignment_elements";

Trix.Attachment.previewablePattern = /^image(\/(gif|png|jpe?g|heic|heif)|$)/;

Array.from(['heading1', 'heading2', 'heading3']).forEach((attr, index) => {
  Trix.config.blockAttributes[attr] = {
    tagName: `h${(index + 1)}`,
    group: false,
    nestable: false,
    terminal: false,
    breakOnReturn: true
  }
});

Array.from(['Left', 'Right', 'Center']).forEach((tagName) => {
  Trix.config.blockAttributes[`align${tagName}`] = {
    tagName: `align-${tagName.toLowerCase()}`,
    nestable: false,
    exclusive: true,
  }
});
