import { Controller } from "stimulus";
import {ajax} from "@rails/ujs";

let debounce = require('lodash/debounce');

export default class extends Controller {
  static targets = ['form'];

  initialize() {
    this.filterOnInput = debounce(this.filterOnInput, 300).bind(this);
    this.filterOnClick = debounce(this.filterOnClick, 200).bind(this);
    this.filterOnInputReset = debounce(this.filterOnInputReset, 100).bind(this);
  }

  filterOnInput() {
    this._loadData();
  }

  filterOnInputReset() {
    this._loadData();
  }

  filterOnClick() {
    this._loadData();
  }

  _loadData() {
    if (this.ajaxRequest) {
      this.ajaxRequest.abort(); // Abort previous ajax request.
    }

    ajax({
      url: this._url(),
      type: "GET",
      dataType: "script",
      beforeSend: (xhr) => {
        this.ajaxRequest = xhr;
        return true;
      }
    })
  }

  _url() {
    let queryString = [];

    Array.from(this.formTarget.elements).forEach(elem => {
      queryString.push([elem.name, elem.value]);
    });

    if (this.formTarget.action.match(/\?/)) {
      return this.formTarget.action + '&' + queryString.map(field => field[0] + '=' + field[1]).join('&');
    } else {
      return this.formTarget.action + '?' + queryString.map(field => field[0] + '=' + field[1]).join('&');
    }
  }
}
