import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ['checkboxAll', 'checkbox'];

  initialize() {
    this.toggle = this.toggle.bind(this);
    this.refresh = this.refresh.bind(this);
  }

  checkboxAllTargetConnected(checkbox) {
    checkbox.addEventListener('change', this.toggle);

    this.refresh();
  }

  checkboxTargetConnected(checkbox) {
    checkbox.addEventListener('change', this.refresh);

    this.refresh();
  }

  checkboxAllTargetDisconnected(checkbox) {
    checkbox.removeEventListener('change', this.toggle);

    this.refresh();
  }

  checkboxTargetDisconnected(checkbox) {
    checkbox.removeEventListener('change', this.refresh);

    this.refresh();
  }

  refresh() {
    const totalCheckboxesCount = this.checkboxTargets.length
    const checkboxesCheckedCount = this.checked.length;

    this.checkboxAllTarget.checked = (totalCheckboxesCount === checkboxesCheckedCount);
  }

  toggle(event) {
    event.preventDefault();

    this.checkboxTargets.forEach(checkbox => {
      if (checkbox.disabled) return;

      checkbox.checked = event.target.checked;

      this.triggerInputEvent(checkbox);
    })
  }

  triggerInputEvent(checkbox) {
    const event = new Event('input', { bubbles: false, cancelable: true });

    checkbox.dispatchEvent(event);
  }

  get checked() {
    return this.checkboxTargets.filter((checkbox) => checkbox.checked);
  }

  get unchecked() {
    return this.checkboxTargets.filter((checkbox) => !checkbox.checked);
  }
}
