import {Controller} from "stimulus";

export default class extends Controller {
  static targets = ['messageContainer', 'errorMessage', 'warningMessage', 'successMessage'];

  static values = {
    defaultMessage: String,
    previewMessage: String
  }

  connect() {
    document.addEventListener('promote:display-success-message', this._displaySuccessMessageFromEvent.bind(this));
    document.addEventListener('promote:display-error-message', this._displayErrorMessageFromEvent.bind(this));
    document.addEventListener('promote:display-warning-message', this._displayWarningMessageFromEvent.bind(this));
  }

  displayStandardErrorMessage() {
    this.displayErrorMessage(this.defaultMessageValue);
  }

  displayPreviewErrorMessage(event) {
    event.preventDefault();
    event.stopPropagation();

    this.displayErrorMessage(this.previewMessageValue);
  }

  displayErrorMessage(message) {
    this.successMessageTarget.classList.add('hidden');
    this.warningMessageTarget.classList.add('hidden');

    this.errorMessageTarget.querySelector('.js-toast-message-text').innerHTML = message;
    this.errorMessageTarget.classList.remove('hidden');
    this.messageContainerTarget.classList.remove('hidden');
  }

  displaySuccessMessage(message) {
    this.errorMessageTarget.classList.add('hidden');
    this.warningMessageTarget.classList.add('hidden');

    this.successMessageTarget.querySelector('.js-toast-message-text').innerHTML = message;
    this.successMessageTarget.classList.remove('hidden');
    this.messageContainerTarget.classList.remove('hidden');
  }

  displayWarningMessage(message) {
    this.errorMessageTarget.classList.add('hidden');
    this.successMessageTarget.classList.add('hidden');

    this.warningMessageTarget.querySelector('.js-toast-message-text').innerHTML = message;
    this.warningMessageTarget.classList.remove('hidden');
    this.messageContainerTarget.classList.remove('hidden');
  }

  _displaySuccessMessageFromEvent() {
    this.displaySuccessMessage(event.detail.message);
  }

  _displayErrorMessageFromEvent() {
    this.displayErrorMessage(event.detail.message);
  }

  _displayWarningMessageFromEvent() {
    this.displayWarningMessage(event.detail.message);
  }
}
