import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['body'];

  open(evt) {
    const modalTrigger = evt.target.classList.contains('[data-modal-trigger]') ? evt.target :
      evt.target.closest('[data-modal-trigger]');
    const modal = document.getElementById(modalTrigger.dataset.modalId);

    if (modal) {
      let inputFields = modal.querySelectorAll('.input-form')

      inputFields.forEach(field => {
        if (field.classList.contains('error')) {
          field.classList.remove('error')
        }
      })

      modal.classList.remove('hidden');
    } else {
      const newModal = document.getElementById('modal-template')
        .content.firstElementChild.cloneNode(true);

      newModal.id = modalTrigger.dataset.modalId;

      fetch(modalTrigger.dataset.modalUrl, { headers: { 'Accept': 'text/html' } })
        .then(response => response.text())
        .then(html => {
          document.body.appendChild(newModal);

          newModal.querySelector('[data-modal-content]').innerHTML = html;
          newModal.querySelector('[data-modal-title]').innerHTML = modalTrigger.dataset.modalTitle;

          this.hideModalIcon(modalTrigger, newModal);

          newModal.classList.remove('hidden');
        });
    }

    this.bodyTarget.classList.add('overflow-hidden');
  }

  hideModalIcon(modalTrigger, modal) {
    if (modalTrigger.dataset.modalHideIcon === 'true') {
      modal.querySelector('[data-test-close-modal]').classList.add('hidden');
    }
  }

  close(evt) {
    evt.target.closest('[data-modal-root]').classList.add('hidden');
    this.bodyTarget.classList.remove('overflow-hidden');
  }
}
