import { Controller } from "stimulus";

import { ajax } from "@rails/ujs";

export default class extends Controller {
  static targets = ["form"];

  static values = {
    successMessage: String
  };

  static outlets = ['flash-messages'];

  async submit(event) {
    event.preventDefault();

    this.formWithErrors = false;

    await this.verifyForms();

    if (this.formWithErrors) return;

    await this.submitForms();
  }

  async verifyForms() {
    // HTML validation
    this.formTargets.forEach(form => {
      if (!form.reportValidity()) {
        this.formWithErrors = true;
      }
    });
  }

  async submitAndExit(event) {
    await this.submit(event);

    if (!this.formWithErrors) {
      document.dispatchEvent(new CustomEvent('promote:close-editor'));
    }
  }

  async submitForms() {
    const promises = this.formTargets.map(form => {
      return new Promise((resolve, reject) => {
        const formData = new FormData(form);
        formData.append("form_submit", "true");

        if (form.querySelector('[data-confirm]')) {
          this._addConfirmationListeners(resolve, () => {
            this._continueSubmission(form, formData, resolve);
          });
          return;
        }

        this._continueSubmission(form, formData, resolve);
      });
    });

    try {
      await Promise.all(promises);

      if (this.formWithErrors) {
        this._displayErrorMessage();
      } else {
        this._displaySuccessMessage();
      }
    } catch (error) {
      this._displayErrorMessage();
    }
  }

  _continueSubmission(form, formData, resolve) {
    ajax({
      url: form.action,
      type: form.method,
      data: formData,
      dataType: "script",
      beforeSend: (xhr) => {
        this.ajaxRequest = xhr;
        return true;
      },
      success: () => {
        resolve();
      },
      error: (xhr, status, error) => {
        this.formWithErrors = true;
        resolve();
      }
    });
  }

  _addConfirmationListeners(resolve, confirmCallback) {
    const modalConfirm = document.querySelector("#confirmation-modal .modal-delete-button");
    const modalCancel = document.querySelector("#confirmation-modal .modal-cancel-button");

    const onConfirm = () => {
      confirmCallback();
      _removeListeners();
    };

    const onCancel = () => {
      resolve();
      _removeListeners();
    };

    modalConfirm.addEventListener("click", onConfirm);
    modalCancel.addEventListener("click", onCancel);

    const _removeListeners = () => {
      modalConfirm.removeEventListener("click", onConfirm);
      modalCancel.removeEventListener("click", onCancel);
    };
  }

  _displayErrorMessage() {
    const message = this.formTarget.dataset.formSubmitErrorMessage;

    this.flashMessagesOutlet.displayErrorMessage(message);
  }

  _displaySuccessMessage() {
    this.flashMessagesOutlet.displaySuccessMessage(this.successMessageValue);
  }
}
