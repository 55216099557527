import { ajax } from '@rails/ujs';

export class TrixImage {
  ALLOWED_IMAGE_TYPES = [
    'image/jpeg', 'image/png', 'image/gif', 'image/bmp', 'image/x-icon'
  ];

  PRESETS_WIDTH = {
    thumb: 40,
    small: 256,
    medium: 512,
    large: 1024
  }

  PRESET_CLASSES = [
    'trix-image--original',
    'trix-image--thumbnail',
    'trix-image--small',
    'trix-image--medium',
    'trix-image--large'
  ];

  constructor(event, trixAttachment) {
    this.editor = event.target.editor;
    this.trixElement = event.target;
    this.formIDs = this.trixElement.form.action.split('/programs/')[1].split('/');
    this.trixAttachment = trixAttachment;
  }

  init() {
    this.selectImagePresets();
  }

  selectImagePresets() {
    const galleryImage = this.trixAttachment.parentElement.classList.contains('attachment-gallery');
    const attachmentToolbar = this.trixAttachment.querySelector('.attachment__toolbar');
    const attachmentActions = attachmentToolbar.querySelector('.trix-button-group--actions');
    const attachmentType = this.trixAttachment.dataset.trixContentType;

    if (!attachmentType.startsWith('image')) {
      return;
    }

    const isEmbedAttachment = !attachmentType.startsWith('image') ||
      attachmentType == "application/octet-stream" ||
      attachmentType == "undefined";

    const isGifImage = attachmentType == "image/gif";

    if (isGifImage) {
      this.trixAttachment.style.display = 'block';
    }

    if (!attachmentActions || galleryImage || isGifImage) {
      return;
    }

    if (isEmbedAttachment) {
      return;
    }

    attachmentActions.classList.remove('with-embeds');

    const removeAttachmentIcon = attachmentActions.querySelector('.trix-button--remove');
    removeAttachmentIcon.insertAdjacentHTML("afterend", this.imageSizeButtons());

    Array.from(
      attachmentActions.querySelectorAll('[data-trix-action="resizeImage"]')
    ).forEach((tab) => {
      tab.addEventListener('click', this.resizeAttachment.bind(this));
    });

    this.setDefaultTabSize(this.trixAttachment);
  }

  setDefaultTabSize(imageFigureEl) {
    let presetSelector = null;
    let imageEl = imageFigureEl.querySelector('img');
    let presetIcons = imageFigureEl.querySelectorAll('.__tabs button');

    let thumbWidth = this.PRESETS_WIDTH['thumb'];
    let smallWidth = this.PRESETS_WIDTH['small'];
    let mediumWidth = this.PRESETS_WIDTH['medium'];
    let largeWidth = this.PRESETS_WIDTH['large'];

    let imageAttributes = imageFigureEl.attributes.getNamedItem('data-trix-attachment');
    let imageWidth = imageEl.width || JSON.parse(imageAttributes.value).width;

    if (imageWidth <= thumbWidth && imageWidth < smallWidth) {
      presetSelector = 'trix-image--thumbnail';
      this.setThumbImageToolbar(imageFigureEl);
    } else if (imageWidth <= smallWidth && imageWidth < mediumWidth) {
      presetSelector = 'trix-image--small';
      this.setSmallImageToolbar(imageFigureEl);
    } else if (imageWidth <= mediumWidth && imageWidth < largeWidth) {
      presetSelector = 'trix-image--medium';
      this.setMediumImageToolbar(imageFigureEl);
    } else if (imageWidth <= largeWidth) {
      presetSelector = 'trix-image--large';
      this.setLargeImageToolbar(imageFigureEl);
    } else {
      presetSelector = 'trix-image--original';
      this.setOriginalToolbar(imageFigureEl);
    }

    Array.from(presetIcons).forEach(tab => tab.classList.add(presetSelector))
  }

  resizeAttachment(event) {
    // extract the type of resize from the data attributes
    const resizeAttribute = event.currentTarget.dataset.trixAttribute.replace('Image', '');

    this.resizeImage(resizeAttribute);
  }

  async resizeImage(resizeType) {
    this.resizeType = resizeType;

    this._setAttachment();
    this.markActivePreset(resizeType);
    this.renderResizeTabs(resizeType);
    this.processRequest(resizeType);
  }

  _setAttachment() {
    this.attachment = this.editor.composition.editingAttachment;
  }

  markActivePreset(resizeType) {
    const imgSelector = 'figure[data-trix-mutable]';
    const activeSelector = `trix-image--${resizeType}`;
    const btnSelector = activeSelector.replace('trix-image--', 'trix-button--image-');
    const presetButton = this.trixElement.querySelector(`${imgSelector} .${btnSelector}`);
    const resizeTabs = this.trixElement.querySelectorAll(`${imgSelector} .__tabs button`);

    Array.from(resizeTabs)
      .forEach(tab => this.toggleActiveTabs(tab, activeSelector));

    if (presetButton) {
      presetButton.classList.add('-active');
    }
  }

  toggleActiveTabs(tab, activeSelector) {
    this.PRESET_CLASSES.forEach(className => tab.classList.remove(className));
    tab.classList.add(activeSelector);
    tab.classList.remove('-active');
  }

  setThumbImageToolbar(trixImage) {
    trixImage.style.display = 'block';
    trixImage.style.marginTop = '120px';
    trixImage.style.width = `${this.PRESETS_WIDTH['thumb']}px`;

    trixImage.querySelector('.__tabs').classList.add('-thumbnail');
    trixImage.querySelector('.trix-button-group--actions').classList.add('-thumbnail');
    trixImage.querySelector('.trix-button--remove').classList.add('-thumbnail');
  }

  setSmallImageToolbar(trixImage) {
    this.resetTabSize(trixImage);

    trixImage.querySelector('.__tabs').classList.add('-small');
    trixImage.querySelector('.trix-button-group--actions').classList.add('-small');
    trixImage.querySelector('.trix-button--remove').classList.add('-small');
    trixImage.style.width = `${this.PRESETS_WIDTH['small']}px`;
  }

  setMediumImageToolbar(trixImage) {
    this.resetTabSize(trixImage);

    trixImage.style.width = `${this.PRESETS_WIDTH['medium']}px`;
  }

  setLargeImageToolbar(trixImage) {
    this.resetTabSize(trixImage);

    trixImage.style.width = `${this.PRESETS_WIDTH['large']}px`;
  }

  setOriginalToolbar(trixImage) {
    this.resetTabSize(trixImage);
    const imageWidth = this.originalWidth();

    trixImage.style.width = `${imageWidth}px`;
  }

  resetTabSize(trixImage) {
    trixImage.style.marginTop = '40px';
    trixImage.style.display = 'inline-block';

    ['-small', '-thumbnail'].forEach(className => {
      trixImage.querySelector('.trix-button-group--actions').classList.remove(className);
      trixImage.querySelector('.__tabs').classList.remove(className);
      trixImage.querySelector('.trix-button--remove').classList.remove(className);
    })
  }

  renderResizeTabs(resizeType) {
    const image = this.trixElement.querySelector('figure[data-trix-mutable]');
    const resizeTabs = Array.from(image.querySelectorAll('.__tabs button'));

    switch (resizeType) {
      case "thumbnail":
        resizeTabs.forEach(tab => tab.classList.add('trix-image--thumbnail'));
        return this.setThumbImageToolbar(image);
      case "small":
        resizeTabs.forEach(tab => tab.classList.add('trix-image--small'));
        return this.setSmallImageToolbar(image);
      case "medium":
        resizeTabs.forEach(tab => tab.classList.add('trix-image--medium'));
        return this.setMediumImageToolbar(image);
      case "large":
        resizeTabs.forEach(tab => tab.classList.add('trix-image--large'));
        return this.setLargeImageToolbar(image);
      case "original":
        resizeTabs.forEach(tab => tab.classList.add('trix-image--original'));
        return this.setOriginalToolbar(image);
    }
  }

  async processRequest(resizeType) {
    await this.sendResizeRequest(resizeType);
  }

  async sendResizeRequest(resizeType) {
    const formData = this.buildFormData(resizeType);

    ajax({
      type: "POST",
      url: this.resizeUrl(),
      data: formData,
      dataType: 'json',
      success: (response)  => this.handleResponse(response)
    })
  }

  buildFormData(resizeType) {
    let sgid = this.attachment.attributes.values.sgid;

    if (!sgid) {
      sgid = this.attachment.fileObjectURL;
    }

    let formData = new FormData();
    formData.append("sgid", sgid);

    if (resizeType === "original" ) {
      formData.append("width", this.originalWidth());
      formData.append("height", this.originalHeight());
    } else {
      const size = this.presetWidth(resizeType);
      formData.append("width", size);
      formData.append("height", size);
    }

    return formData;
  }

  resizeUrl() {
    const programID = this.formIDs.at(0);

    return `/programs/${programID}/attachments/resize_image`;
  }

  handleResponse(response) {
    if (response.hasOwnProperty('result')) {
      this.saveResizedResult(response);
    } else if (response.hasOwnProperty('error')) {
      this.renderResizeError(response);
    }
  }

  saveResizedResult(response) {
    const resizedURL = response.result.url;

    if (resizedURL) {
      this.attachment.previewURL = resizedURL;
      this.attachment.preloadingURL = resizedURL;

      if (this.attachment.previewDelegate.image) {
        this.attachment.previewDelegate.image.src = resizedURL;
        this.attachment.previewDelegate.image.width = this.presetWidth(this.resizeType);
      }

      this.attachment.setAttributes({ url: resizedURL, href: resizedURL });
    }

    this.attachment.preload();
  }

  renderResizeError(response) {
    const errorMessage =  document.querySelector('[data-flash-messages-target="errorMessage"]');
    const warningMessage =  document.querySelector('[data-flash-messages-target="warningMessage"]');
    const successMessage = document.querySelector('[data-flash-messages-target="successMessage"]');

    successMessage.classList.add('hidden');
    warningMessage.classList.add('hidden');

    errorMessage.querySelector('.js-toast-message-text').innerHTML = response.error;
    errorMessage.classList.remove('hidden');
    document.querySelector('[data-flash-messages-target="messageContainer"]').classList.remove('hidden');
  }

  presetWidth(resizeType) {
    switch (resizeType) {
      case "thumbnail":
        return this.PRESETS_WIDTH['thumb'];
      case "small":
        return this.PRESETS_WIDTH['small'];
      case "medium":
        return this.PRESETS_WIDTH['medium'];
      case "large":
        return this.PRESETS_WIDTH['large'];
      case "original":
        return `${this.originalWidth()}px`;
    }
  }

  originalWidth() {
    if (this.originalDimensions) {
      return this.originalDimensions.width;
    } else {
      if (typeof this.attachment == 'undefined') {
        this.attachment = this.editor.composition.editingAttachment;
      }

      return this.attachment.attributes.values.width;
    }
  }

  originalHeight() {
    if (this.originalDimensions) {
      return this.originalDimensions.height;
    } else {
      return this.attachment.attributes.values.height;
    }
  }

  imageSizeButtons() {
    const lang = PromoteRichTextEditor.translations;

    return `
      <nav class="__tabs">
        <button type="button"
                title="${lang.originalImageSize}"
                class="__tab trix-button trix-button--image-original"
                data-trix-attribute="originalImage"
                data-trix-action="resizeImage"
                data-trix-mutable="true"
                tabindex="-1">
                  ${lang.originalImageSize}
                </button>
        <button type="button"
                  title="${lang.thumbnailImageSize}"
                  class="__tab trix-button trix-button--image-thumbnail"
                  data-trix-attribute="thumbnailImage"
                  data-trix-action="resizeImage"
                  data-trix-mutable="true"
                  tabindex="-1">
                    ${lang.thumbnailImageSize}
                  </button>
        <button type="button"
                title="${lang.smallImageSize}"
                class="__tab trix-button trix-button--image-small"
                data-trix-attribute="smallImage"
                data-trix-action="resizeImage"
                data-trix-mutable="true"
                tabindex="-1">
                  ${lang.smallImageSize}
                </button>
        <button type="button"
                title="${lang.mediumImageSize}"
                class="__tab trix-button trix-button--image-medium"
                data-trix-attribute="mediumImage"
                data-trix-action="resizeImage"
                data-trix-mutable="true"
                tabindex="-1">
                  ${lang.mediumImageSize}
                </button>
        <button type="button"
                title="${lang.largeImageSize}"
                class="__tab trix-button trix-button--image-large"
                data-trix-attribute="largeImage"
                data-trix-action="resizeImage"
                data-trix-mutable="true"
                tabindex="-1">
                  ${lang.largeImageSize}
                </button>
      </nav>`;
  }
}
