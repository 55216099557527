import { Controller } from "stimulus";
import { ajax } from '@rails/ujs';

export default class extends Controller {
  static targets = ['container', 'content'];

  static outlets = ['overview--sync'];


  static values = {
    programId: String,
    contentPageId: String
  }

  connect() {
    this._boundOpenEditor = this._openEditor.bind(this);
    this._boundClose = this.close.bind(this);
    this._boundCloseWithoutSync = this._closeWithoutSync.bind(this);

    // We store the bound methods and use them for event listeners to ensure the
    // same function reference is used for both adding and removing listeners.
    // This prevents the issue of multiple listeners being added when the controller reconnects.
    document.addEventListener('promote:open-editor', this._boundOpenEditor);
    document.addEventListener('promote:close-editor', this._boundClose);
    document.addEventListener('promote:close-editor-without-sync', this._boundCloseWithoutSync);

    if (this.contentPageIdValue) {
      this._updateUrlOnOpen();
      this._loadBuilder();
    }
  }

  disconnect() {
    document.removeEventListener('promote:open-editor', this._boundOpenEditor);
    document.removeEventListener('promote:close-editor', this._boundClose);
    document.removeEventListener('promote:close-editor-without-sync', this._boundCloseWithoutSync);
  }

  async verifyFormsAndClose() {
    try {
      await this.verify();

      this.close();
    } catch (e) {
      // no-op
    }
  }

  async verify() {
    const unloadChecker = this.application
      .getControllerForElementAndIdentifier(document.body, "unload-checker");

    return await unloadChecker.verify();
  }

  followUrl(event) {
    event.preventDefault();

    let { url } = event.currentTarget.dataset;

    this.verify().then(() => {
      try {
        ajax({
          url,
          type: 'get',
          dataType: 'script'
        });
      } catch (_e) {
        return;
      }
    }).catch(_e => {});
  }

  close() {
    this._closeAndUpdateOverview();
  }

  _closeWithoutSync() {
    this._closeAndUpdateOverview(false);
  }

  _closeAndUpdateOverview(sync = true) {
    this._closeEditor();

    this._updateUrlOnClose();
    this._deselectAllCards();
    this._highlightModule();

    if (sync) {
      this.overviewSyncOutlet.sync(this.programIdValue, this.contentPageIdValue);
    }

    this.contentPageIdValue = null;
  }

  _openEditor() {
    this.containerTarget.classList.remove('initial-state');
    this.containerTarget.classList.remove('closed');

    document.dispatchEvent(new CustomEvent('promote:shortcuts--register', {
      detail: {
        key: 'Escape',
        group: 'editor',
        handler: this.verifyFormsAndClose.bind(this)
      }
    }));
  }

  _closeEditor() {
    this.containerTarget.classList.add('closed');
    this.contentTarget.innerHTML = '';
    document.dispatchEvent(new CustomEvent('promote:shortcuts--unregister', {
      detail: { group: 'editor' }
    }));
  }

  _deselectAllCards() {
    let selectedCards = document.querySelectorAll('.js-overview-module .selected');

    selectedCards.forEach((card) => {
      card.classList.remove('selected');
    });
  }

  _highlightModule() {
    if (!this.hasContentPageIdValue) {
      return;
    }

    const currentContentPage = document
      .getElementById(`js-overview-content-page-card-${this.contentPageIdValue}`);

    if (!currentContentPage) {
      return;
    }

    const currentModule = currentContentPage.closest('[id*=js-overview-module-card-content-]');

    if (currentModule) {
      document.dispatchEvent(new CustomEvent('promote:highlight', {
        detail: { element: currentModule }
      }));
    }
  }

  _updateUrlOnClose() {
    let url = `/admin/programs/${this.programIdValue}/overview/program_modules`;

    window.history.pushState({}, "", url.split('?')[0]);
  }

  _updateUrlOnOpen() {
    window.history.pushState({}, "", this._openUrl());
  }

  _loadBuilder() {
    if (this.ajaxRequest) {
      this.ajaxRequest.abort(); // Abort previous ajax request.
    }

    ajax({
      url: this._openUrl(),
      type: "GET",
      dataType: "script",
      beforeSend: (xhr) => {
        this.ajaxRequest = xhr;
        return true;
      }
    })
  }

  _openUrl() {
    return `/admin/programs/${this.programIdValue}/editor/content_pages/${this.contentPageIdValue}/edit`;
  }
}
