import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["text", "notice"];

  copy = () => {
    const copyText = this.textTarget;
    navigator.clipboard.writeText(copyText.value.trim());

    this.showNotice();
    this.hideNotice();
  };

  showNotice = () => {
    this.noticeTarget.classList.remove("hidden");
  };

  hideNotice = () => {
    const notice = this.noticeTarget;

    setTimeout(function () {
      notice.classList.add("hidden");
    }, 2000);
  };
}
