import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["button"];

  select(event) {
    this._removeBackgrounds();
    this._setBackground(event.target);
  }

  _removeBackgrounds() {
    this.buttonTargets.forEach(button => {
      button.classList.remove('primary-stroke-primary-700', 'bg-primary-200');
      button.classList.add('primary-stroke-gray-800', 'bg-gray-100');
    })
  }

  _setBackground(target) {
    target.classList.remove('primary-stroke-gray-800', 'bg-gray-100');
    target.classList.add('primary-stroke-primary-700', 'bg-primary-200');
  }
}
