import { Controller } from "stimulus";

export default class extends Controller {
  connect() {
    const modulesContainer = document.getElementById('js-overview-modules-list');
    this.container = document.getElementById('js-overview-empty-state-card');

    modulesContainer.addEventListener('change', this._checkProgramModuleState(modulesContainer));
  }

  _checkProgramModuleState(elem) {
    if (elem.querySelectorAll('div').length === 0) {
      this._showEmptyStateMessageContainer();
    }
  }

  _showEmptyStateMessageContainer() {
    this.container.style.display = 'flex';
  }
}
