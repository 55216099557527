import { RichTextEditor } from '../promote_ui/init_rich_text_editor';
import { AdvancedEditorTemplate } from './trix/advanced_editor_template';
import { TrixAlignment } from "./trix/trix_alignment";
import { TrixEditor } from "./trix/trix_editor";
import { TrixEmbed } from "./trix/trix_embed";
import { TrixHeading } from "./trix/trix_heading";
import { TrixLink } from "./trix/trix_link";
import { TrixImageResize } from "./trix/trix_image_resize_toolbar";
import { TrixShareEmbedToolbar } from "./trix/trix_share_embed_toolbar";

document.addEventListener("trix-initialize", (event) => {
  if (event.target.dataset.adminTrix) {
    new AdvancedEditorTemplate(event.target.editor);

    const trixAlignment = new TrixAlignment(event);
    trixAlignment.init();

    const trixEditor = new TrixEditor(event);
    trixEditor.init();

    const trixEmbed = new TrixEmbed(event);
    trixEmbed.init();

    const trixHeading = new TrixHeading(event);
    trixHeading.init();

    const trixLink = new TrixLink(event);
    trixLink.init();

    const trixImageResize = new TrixImageResize(event);
    trixImageResize.init();

    const trixShareEmbedToolbar = new TrixShareEmbedToolbar(event);
    trixShareEmbedToolbar.init();
  } else {
    new RichTextEditor(event.target);
  }
});
