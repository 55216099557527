import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["field", "title", "input", "filter", "list", "listItem"];

  connect() {
    if (this.listItemTargets[0].dataset.value !== this.titleTarget.innerHTML) {
      this._setBackground();
    }
  }

  toggle(event) {
    if (this.fieldTarget.classList.contains("active")) {
      this.fieldTarget.classList.remove("active");
      this.resetFilter();
    } else {
      this._removeBackground();

      this.fieldTarget.classList.add("active");

      if (this.hasFilterTarget) {
        this.filterTarget.focus();
      }
    }
  }

  hide(event) {
    if (event && this.element.contains(event.target)) {
      return;
    }

    this.fieldTarget.classList.remove("active");
    this.resetFilter();
  }

  search(event) {
    const searchContent = event.currentTarget.value;

    this.filter(searchContent);
  }

  filter(value) {
    const showAll = value == "";

    this.listItemTargets.forEach(function (listItem) {
      const match = listItem.dataset["value"]
        .toLowerCase()
        .includes(value.toLowerCase());

      if (match || showAll) {
        listItem.classList.remove("hidden");
      } else {
        listItem.classList.add("hidden");
      }
    });
  }

  select(event) {
    this.titleTarget.innerHTML = event.currentTarget.dataset["value"];
    this.inputTarget.value = event.currentTarget.dataset["id"];

    if (this.listItemTargets.indexOf(event.currentTarget) === 0) {
      this._removeBackground();
    } else {
      this._setBackground();
    }

    const hideAfterEvent = function () {
      this.fieldTarget.classList.remove("active");
    }.bind(this);

    setTimeout(hideAfterEvent, 1);
  }

  resetFilter() {
    if (this.hasFilterTarget) {
      this.filter("");
      this.filterTarget.value = "";
    }
  }

  _setBackground() {
    this.fieldTarget.classList.remove('bg-white');
    this.fieldTarget.classList.add('bg-primary-100');
  }

  _removeBackground() {
    this.fieldTarget.classList.add('bg-white');
    this.fieldTarget.classList.remove('bg-primary-100');
  }
}
