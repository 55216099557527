export class AdvancedEditorTemplate {
  constructor(editor) {
    this.setupEditorToolbar(editor.element.toolbarElement)
  }

  setupEditorToolbar = (toolbar) => {
    toolbar.innerHTML = this.advancedEditorTemplate()
  }

  advancedEditorTemplate = () => {
    const lang = PromoteRichTextEditor.translations;

    return `<div class="trix-button-row admin-template">
      <span class="trix-button-group trix-button-group--text-tools"
            data-trix-button-group="text-tools">
        <button type="button"
                class="trix-button trix-button--icon trix-button--icon-bold"
                data-trix-attribute="bold"
                data-trix-key="b"
                title="${lang.boldTitle}"
                tabindex="-1">${this.boldSVG()}</button>
        <button type="button"
                class="trix-button trix-button--icon trix-button--icon-italic"
                data-trix-attribute="italic"
                data-trix-key="i"
                title="${lang.italicTitle}"
                tabindex="-1">${this.italicSVG()}</button>
        <button type="button"
                 class="trix-button trix-button--icon trix-button--icon-link trix-link-icon"
                 data-trix-attribute="href"
                 data-trix-action="link"
                 data-trix-key="k"
                 title="${lang.linkTitle}"
                 tabindex="-1">${this.linkIcon()}</button>
      </span>
      <span class="trix-button-group trix-button-group--block-tools"
                  data-trix-button-group="block-tools">
              <select type="button"
                      class="trix-header-select"
                      data-trix-action="x-heading"
                      data-behavior="header_selection"
                      title="${lang.headingTitle}"
                      tabindex="-1">
                <option class="trix-picker-label" selected>${lang.headingDefault}</option>
                <option class="trix-picker-label" data-trix-attribute="heading1">
                  ${lang.heading1}
                </option>
                <option class="trix-picker-label" data-trix-attribute="heading2">
                  ${lang.heading2}
                </option>
                <option class="trix-picker-label" data-trix-attribute="heading3">
                  ${lang.heading3}
                </option>
              </select>
            </span>
      <span class="trix-button-group trix-button-group--block-tools"
            data-trix-button-group="block-tools">
        <button type="button"
                class="trix-button trix-button--icon trix-button--icon-number-list"
                data-trix-attribute="number"
                title="${lang.numbersTitle}"
                tabindex="-1">${this.numberSVG()}</button>
        <button type="button"
                class="trix-button trix-button--icon trix-button--icon-bullet-list"
                data-trix-attribute="bullet"
                title="${lang.bulletsTitle}"
                tabindex="-1">${this.bulletSVG()}</button>
        <button type="button"
                class="trix-button trix-button--icon trix-button--icon-quote"
                data-trix-attribute="quote"
                title="${lang.quoteTitle}"
                tabindex="-1">${this.quoteSVG()}</button>
        <button type="button"
                            class="trix-button trix-button--icon trix-button--icon-alignment"
                            data-trix-action="x-alignment"
                            title="${lang.alignTitle}"
                            tabindex="-1">
                      <svg viewBox="0 0 18 18">
                        <line class="trix-stroke" x1="3" x2="15" y1="9" y2="9"></line>
                        <line class="trix-stroke" x1="3" x2="13" y1="14" y2="14"></line>
                        <line class="trix-stroke" x1="3" x2="9" y1="4" y2="4"></line>
                      </svg>
                    </button>
      </span>
      <span class="trix-button-group trix-button-group--file-tools"
            data-trix-button-group="file-tools">
        <button type="button"
                class="trix-button trix-button--icon trix-button--icon-attach"
                data-trix-action="attachFiles"
                title="${lang.attachFiles}"
                tabindex="-1">${this.imageSVG()}</button>
                 <button type="button"
                    class="trix-button trix-button--icon trix-button--icon-embed"
                    data-trix-action="x-embed"
                    title="${lang.embedTitle}"
                    tabindex="-1">
              <svg viewBox="0 0 18 18">
                <rect class="trix-stroke" height="12" width="12" x="3" y="3"></rect>
                <rect class="trix-fill" height="12" width="1" x="5" y="3"></rect>
                <rect class="trix-fill" height="12" width="1" x="12" y="3"></rect>
                <rect class="trix-fill" height="2" width="8" x="5" y="8"></rect>
                <rect class="trix-fill" height="1" width="3" x="3" y="5"></rect>
                <rect class="trix-fill" height="1" width="3" x="3" y="7"></rect>
                <rect class="trix-fill" height="1" width="3" x="3" y="10"></rect>
                <rect class="trix-fill" height="1" width="3" x="3" y="12"></rect>
                <rect class="trix-fill" height="1" width="3" x="12" y="5"></rect>
                <rect class="trix-fill" height="1" width="3" x="12" y="7"></rect>
                <rect class="trix-fill" height="1" width="3" x="12" y="10"></rect>
                <rect class="trix-fill" height="1" width="3" x="12" y="12"></rect>
              </svg>
      </button>
      </span>
      <span class="trix-button-group trix-button-group--custom-tools"
            data-trix-button-group="block-tools">
        <button type="button"
                class="trix-button trix-button--icon-horizontal-rule"
                data-trix-action="x-horizontal-rule"
                title="${lang.dividerTitle}"
                tabindex="-1">-</button>
      </span>
      <span class="trix-button-group trix-button-group--history-tools"
            data-trix-button-group="history-tools">
        <button type="button"
                class="trix-button trix-button--icon trix-button--icon-undo disabled"
                data-trix-action="undo"
                data-trix-key="z"
                title="${lang.undoTitle}"
                tabindex="-1">${this.undoSVG()}</button>
        <button type="button"
                class="trix-button trix-button--icon trix-button--icon-redo disabled"
                data-trix-action="redo" data-trix-key="shift+z"
                title="${lang.redoTitle}"
                tabindex="-1">${this.redoSVG()}</button>
      </span>
      <span class="trix-button-group trix-button-group--custom-tools"
            data-trix-button-group="block-tools">
        <button type="button"
                class="trix-button trix-button--icon trix-button--icon-clean"
                data-trix-action="x-clean"
                title="${lang.cleanTitle}"
                tabindex="-1">${this.cleanSVG()}</button>
      </span>
      <span class="trix-button-group-spacer"></span>
    </div>
    <div class="trix-dialogs" data-trix-dialogs>
        <div class="trix-dialog trix-dialog--link"
           data-trix-dialog="href"
           data-trix-dialog-attribute="href">
            <div class="trix-dialog__link-fields">
                <input type="url" name="href" class="trix-input trix-input--dialog"
                    placeholder="${lang.urlPlaceholder}"
                    aria-label="${lang.url}"
                    disabled="disabled"
                    required data-trix-input>
                <div class="trix-button-group">
                    <input type="button"
                        value="${lang.link}"
                        data-trix-method="setAttribute"
                        class="trix-button trix-button--dialog">
                    <input type="button"
                        value="${lang.unlink}"
                        data-trix-method="removeAttribute"
                        class="trix-button trix-button--dialog">
                </div>
            </div>
        </div>
        <div class="trix-dialog trix-dialog--embed"
                 data-trix-dialog="x-embed"
                 data-trix-dialog-attribute="embedUrl"
                 data-behavior="embed_dialog">
              <span class="embed-text">${lang.embedLabel}</span>
              <input type="url"
                     name="embedUrl"
                     class="trix-input trix-input--dialog"
                     placeholder="${lang.embedPlaceholder}"
                     aria-label="${lang.url}"
                     required
                     data-trix-input
                     disabled="disabled">
              <input type="button"
                     class="trix-button trix-button--dialog"
                     data-behavior="submit_embed"
                     value="${lang.saveButton}">
            </div>
        <div class="trix-dialog trix-dialog--heading"
                 data-trix-dialog="x-heading"
                 data-trix-dialog-attribute="x-heading">
              <div class="trix-dialog__link-fields">
                <input type="text"
                       name="x-heading"
                       class="trix-dialog-hidden__input"
                       data-trix-input>
                <span class="trix-header trix-picker">
                  <span class="trix-picker-label" data-behavior="dropdown_headers">
                    <span class="trix-picker-item" data-trix-attribute="heading1"
                                                   title="${lang.heading1}"></span>
                    <span class="trix-picker-item" data-trix-attribute="heading2"
                                                   title="${lang.heading2}"></span>
                    <span class="trix-picker-item" data-trix-attribute="heading3"
                                                   title="${lang.heading3}"></span>
                    <span class="trix-picker-item trix-default"
                                                   title="${lang.headingDefault}"></span>
                  </span>
                </span>
              </div>
            </div>
        <div class="trix-dialog trix-dialog--alignment"
                 data-trix-dialog="x-alignment"
                 data-trix-dialog-attribute="x-alignment">
              <div class="trix-dialog__alignment-fields">
                <span class="trix-align trix-picker trix-icon-picker">
                  <span class="trix-picker-label">
                    <span class="trix-picker-item trix-picker-alignment"
                          data-trix-attribute="alignLeft"
                          title="${lang.alignLeft}">
                      <svg viewBox="0 0 18 18">
                        <line class="trix-stroke" x1="3" x2="15" y1="9" y2="9"></line>
                        <line class="trix-stroke" x1="3" x2="13" y1="14" y2="14"></line>
                        <line class="trix-stroke" x1="3" x2="9" y1="4" y2="4"></line>
                      </svg>
                    </span>
                    <span class="trix-picker-item trix-picker-alignment"
                          data-trix-attribute="alignCenter"
                          title="${lang.alignCenter}">
                      <svg viewBox="0 0 18 18">
                        <line class="trix-stroke" x1="15" x2="3" y1="9" y2="9"></line>
                        <line class="trix-stroke" x1="14" x2="4" y1="14" y2="14"></line>
                        <line class="trix-stroke" x1="12" x2="6" y1="4" y2="4"></line>
                      </svg>
                    </span>
                    <span class="trix-picker-item trix-picker-alignment"
                          data-trix-attribute="alignRight"
                          title="${lang.alignRight}">
                      <svg viewBox="0 0 18 18">
                        <line class="trix-stroke" x1="15" x2="3" y1="9" y2="9"></line>
                        <line class="trix-stroke" x1="15" x2="5" y1="14" y2="14"></line>
                        <line class="trix-stroke" x1="15" x2="9" y1="4" y2="4"></line>
                      </svg>
                    </span>
                  </span>
                </span>
              </div>
            </div>
    </div>`;
  }

  boldSVG = () => {
    return `<svg viewBox="0 0 18 18">
      <path class="trix-stroke" d="M5,4H9.5A2.5,2.5,0,0,1,12,6.5v0A2.5,2.5,0,0,1,9.5,9H5A0,0,0,0,1,5,9V4A0,0,0,0,1,5,4Z"></path>
      <path class="trix-stroke" d="M5,9h5.5A2.5,2.5,0,0,1,13,11.5v0A2.5,2.5,0,0,1,10.5,14H5a0,0,0,0,1,0,0V9A0,0,0,0,1,5,9Z"></path>
    </svg>`
  }

  italicSVG = () => {
    return `<svg viewBox="0 0 18 18">
      <line class="trix-stroke" x1="7" x2="13" y1="4" y2="4"></line>
      <line class="trix-stroke" x1="5" x2="11" y1="14" y2="14"></line>
      <line class="trix-stroke" x1="8" x2="10" y1="14" y2="4"></line>
    </svg>`
  }

  linkIcon = () => {
    return `<svg viewBox="0 0 18 18">
      <line class="trix-stroke" x1="7" x2="11" y1="7" y2="11"></line>
      <path class="trix-even trix-stroke"
            d="M8.9,4.577a3.476,3.476,0,0,1,.36,4.679A3.476,3.476,0,0,1,4.577,8.9C3.185,
               7.5,2.035,6.4,4.217,4.217S7.5,3.185,8.9,4.577Z"></path>
      <path class="trix-even trix-stroke"
            d="M13.423,9.1a3.476,3.476,0,0,0-4.679-.36,3.476,3.476,0,0,0,.36,4.679c1.392,
               1.392,2.5,2.542,4.679.36S14.815,10.5,13.423,9.1Z"></path>
    </svg>`
  }

  bulletSVG = () => {
    return `<svg viewBox="0 0 18 18">
      <line class="trix-stroke" x1="6" x2="15" y1="4" y2="4"></line>
      <line class="trix-stroke" x1="6" x2="15" y1="9" y2="9"></line>
      <line class="trix-stroke" x1="6" x2="15" y1="14" y2="14"></line>
      <line class="trix-stroke" x1="3" x2="3" y1="4" y2="4"></line>
      <line class="trix-stroke" x1="3" x2="3" y1="9" y2="9"></line>
      <line class="trix-stroke" x1="3" x2="3" y1="14" y2="14"></line>
    </svg>`
  }

  numberSVG = () => {
    return `<svg viewBox="0 0 18 18">
      <line class="trix-stroke" x1="7" x2="15" y1="4" y2="4"></line>
      <line class="trix-stroke" x1="7" x2="15" y1="9" y2="9"></line>
      <line class="trix-stroke" x1="7" x2="15" y1="14" y2="14"></line>
      <line class="trix-stroke trix-thin" x1="2.5" x2="4.5" y1="5.5" y2="5.5"></line>
      <path class="trix-fill" d="M3.5,6A0.5,0.5,0,0,1,3,5.5V3.085l-0.276.138A0.5,0.5,0,0,1,2.053,3c-0.124-.247-0.023-0.324.224-0.447l1-.5A0.5,0.5,0,0,1,4,2.5v3A0.5,0.5,0,0,1,3.5,6Z"></path>
      <path class="trix-stroke trix-thin" d="M4.5,10.5h-2c0-.234,1.85-1.076,1.85-2.234A0.959,0.959,0,0,0,2.5,8.156"></path>
      <path class="trix-stroke trix-thin" d="M2.5,14.846a0.959,0.959,0,0,0,1.85-.109A0.7,0.7,0,0,0,3.75,14a0.688,0.688,0,0,0,.6-0.736,0.959,0.959,0,0,0-1.85-.109"></path>
    </svg>`
  }

  quoteSVG = () => {
    return `<svg viewBox="0 0 18 18">
      <rect class="trix-fill trix-stroke" height="3" width="3" x="4" y="5"></rect>
      <rect class="trix-fill trix-stroke" height="3" width="3" x="11" y="5"></rect>
      <path class="trix-even trix-fill trix-stroke" d="M7,8c0,4.031-3,5-3,5"></path>
      <path class="trix-even trix-fill trix-stroke" d="M14,8c0,4.031-3,5-3,5"></path>
    </svg>`
  }

  undoSVG = () => {
    return `<svg viewBox="0 0 18 18">
      <polygon class="trix-fill trix-stroke" points="6 10 4 12 2 10 6 10"></polygon>
      <path class="trix-stroke" d="M8.09,13.91A4.6,4.6,0,0,0,9,14,5,5,0,1,0,4,9"></path>
    </svg>`
  }

  redoSVG = () => {
    return `<svg viewBox="0 0 18 18">
      <polygon class="trix-fill trix-stroke" points="12 10 14 12 16 10 12 10"></polygon>
      <path class="trix-stroke" d="M9.91,13.91A4.6,4.6,0,0,1,9,14a5,5,0,1,1,5-5"></path>
    </svg>`
  }

  cleanSVG = () => {
    return `<svg viewBox="0 0 18 18">
      <line class="trix-stroke" x1="5" x2="13" y1="3" y2="3"></line>
      <line class="trix-stroke" x1="6" x2="9.35" y1="12" y2="3"></line>
      <line class="trix-stroke" x1="11" x2="15" y1="11" y2="15"></line>
      <line class="trix-stroke" x1="15" x2="11" y1="11" y2="15"></line>
      <rect class="trix-fill" height="1" rx="0.5" ry="0.5" width="7" x="2" y="14"></rect>
    </svg>`
  }

  imageSVG = () => {
    return `<svg viewBox="0 0 18 18">
              <rect class="trix-stroke" height="10" width="12" x="3" y="4"></rect>
              <circle class="trix-fill" cx="6" cy="7" r="1"></circle>
              <polyline class="trix-even trix-fill"
                        points="5 12 5 11 7 9 8 10 11 7 13 9 13 12 5 12">
              </polyline>
            </svg>`;
  }
}
