import { Controller } from "stimulus";
import {ajax} from "@rails/ujs";

export default class extends Controller {
  sort(event) {
    if (this.ajaxRequest) {
      this.ajaxRequest.abort(); // Abort previous ajax request.
    }

    ajax({
      url: event.target.dataset.sortableUrl,
      type: 'GET',
      dataType: 'script',
      beforeSend: (xhr) => {
        this.ajaxRequest = xhr;
        return true;
      }
    })
  }
}
