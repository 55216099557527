import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ["card"];
  static values = { text: String };

  toggle() {
    if ( this.hasCardTarget ) {
      this.cardTarget.classList.toggle("hidden");
    }
  }

  show() {
    if ( this.hasCardTarget && this.cardTarget.classList.contains("hidden") ) {
      this.cardTarget.classList.remove("hidden");
    }
  }

  hide() {
    if ( this.hasCardTarget && !this.cardTarget.classList.contains("hidden") ) {
      this.cardTarget.classList.add("hidden");
    }
  }
}
