export class TrixAlignment {
  constructor(event) {
    this.editor = event.target.editor;
    this.trixElement = this.editor.element;
    this.toolbar = this.trixElement.toolbarElement;
    this.alignmentButton = this.toolbar.querySelector("[data-trix-action='x-alignment']");
    this.alignmentDialog = this.toolbar.querySelector("[data-trix-dialog='x-alignment']");
    this.alignmentElements = this.alignmentDialog.querySelectorAll(".trix-picker-alignment");
  }

  init() {
    this.initEventListeners();
  }

  initEventListeners() {
    this.alignmentButton.addEventListener("click", this.onClick.bind(this));

    Array.from(this.alignmentElements).forEach((icon) => {
      icon.addEventListener("click", this.onIconClick.bind(this));
    });
  }

  onClick(event){
    if (event.target.classList.contains('open')) {
      this.hideDialogOptions();
    } else {
      this.showDialogOptions();
    }
  }

  onIconClick(event) {
    this.hideDialogOptions();

    let range = this.editor.getDocument().getPieceAtPosition(event.currentTarget.slot);
    this.updateInRange(range, 0, () => this.alignSelectedAttributes);
  }

  updateInRange(range, offset, updateFunc) {
    const baseRange = this.editor.getSelectedRange();
    this.editor.setSelectedRange(range);
    updateFunc();
    this.editor.setSelectedRange([baseRange[0] + offset, baseRange[1] + offset]);
  }

  showDialogOptions() {
    this.trixElement.blur();
    this.alignmentDialog.classList.remove('hidden');
    this.alignmentButton.classList.add('open');
    this.alignmentButton.setAttribute('data-trix-active', '');
  }

  hideDialogOptions() {
    this.trixElement.focus();
    this.alignmentDialog.classList.add('hidden');
    this.alignmentButton.classList.remove('open');
    this.alignmentButton.removeAttribute('data-trix-active');
  }
}
